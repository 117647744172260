import { memo, useState } from "react";
import { Flex } from "@mantine/core";
import { PageLayout } from "src/components/page-layout";
import { StoreCreatorSlider } from "src/modules/store-creator/components/store-creator-slider";
import {
  StoreCreatorFirstStep,
  StoreCreatorSecondStep,
} from "src/modules/store-creator/steps";

export const StoreCreatorPage = memo(() => {
  const [activeStep, setActiveStep] = useState(1);

  const handleSlideChange = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <PageLayout>
      <Flex direction="column" py={40} px={{ base: 20, md: 50 }} h="100%">
        <StoreCreatorSlider />

        {activeStep === 1 && (
          <StoreCreatorFirstStep onContinueClick={handleSlideChange} />
        )}

        {activeStep === 2 && <StoreCreatorSecondStep />}
      </Flex>
    </PageLayout>
  );
});
