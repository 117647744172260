import { FC, memo } from "react";
import { Flex, Text } from "@mantine/core";
import { DatePickerInput, DateValue } from "@mantine/dates";
import { IconX } from "@tabler/icons-react";
import { FilterDateType } from "src/modules/products-screens/types/filter-values";

interface FilterDateProps {
  selectedDate: FilterDateType;
  setSelectedDate: (newDate: FilterDateType) => void;
}

type DateType = "From" | "To";

export const FilterDate: FC<FilterDateProps> = memo(
  ({ selectedDate, setSelectedDate }) => {
    const dateTypes: DateType[] = ["From", "To"];

    const changeDate = (dateType: DateType, date: DateValue | null) => {
      setSelectedDate({
        ...selectedDate,
        [dateType === "From" ? "fromDate" : "toDate"]: date,
      });
    };

    const deleteSelectedDate = (dateType: DateType) => {
      changeDate(dateType, null);
    }

    return (
      <Flex direction="column" gap={10}>
        {dateTypes.map((dateType, index) => (
          <Flex key={index} align="center" gap={20}>
            <Text fz={14} c="grey">
              {dateType}:
            </Text>

            <Flex align="center" gap={5}>
              <DatePickerInput
                placeholder="Pick date"
                value={
                  dateType === "From"
                    ? selectedDate.fromDate
                    : selectedDate.toDate
                }
                onChange={(date: DateValue) => changeDate(dateType, date)}
                radius={10}
                styles={{
                  calendarHeaderControl: {
                    width: "30px",
                  },
                  calendarHeaderLevel: {
                    fontWeight: 600,
                    fontSize: 18,
                  },
                  calendarHeader: {
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: 10,
                  },
                  calendarHeaderControlIcon: {
                    width: "50px",
                    height: "50px",
                    margin: "0 auto",
                  },
                  day: {
                    textAlign: "center",
                    backgroundColor: "#f3f1f1",
                    borderRadius: 5,
                    padding: 5,
                    width: "100%",
                  },
                  month: {
                    margin: "0 auto",
                    width: "100%",
                  },
                  monthsList: {
                    margin: "0 auto",
                  },
                  monthsListCell: {
                    backgroundColor: "#f3f1f1",
                    borderRadius: 5,
                    padding: 10,
                  },
                  yearsList: {
                    margin: "0 auto",
                  },
                  yearsListCell: {
                    backgroundColor: "#f3f1f1",
                    borderRadius: 5,
                    padding: 10,
                  },
                }}
              />

              <IconX color="gray" onClick={() => deleteSelectedDate(dateType)} size={12} className="cursor-pointer hover:scale-125 transition-all duration-200" />
            </Flex>
          </Flex>
        ))}
      </Flex>
    );
  }
);
