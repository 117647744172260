import { memo } from 'react';
import { SimpleGrid, Text } from '@mantine/core';
import { useStoreContext } from 'src/contexts';

export const StoreInfoData = memo(() => {
  const { storeFullData } = useStoreContext();

  const mockedSoldProducts = 0; // TODO: add a real data
  const mockedCreatedArtwork = 0; // TODO: add a real data

  return (
    <SimpleGrid
      cols={{ base: 2, xs: 3 }}
      c="grey"
      verticalSpacing={10}
      h="max-content"
    >
      <Text ta={{ base: 'center', xs: 'left' }}>
        Created Products: {storeFullData?.products?.product_count || 0}
      </Text>
      <Text ta="center">
        Created Persona: {storeFullData?.products?.product_count || 0}
      </Text>
      <Text ta={{ base: 'center', xs: 'right' }}>
        Created Persona: {storeFullData?.persona?.persona_count || 0}
      </Text>
      <Text ta={{ base: 'center', xs: 'left' }}>
        Sold Products: {mockedSoldProducts}
      </Text>
      <Text ta="center">Created Artwork: {mockedCreatedArtwork}</Text>
      <Text ta={{ base: 'center', xs: 'right' }}>
        Created Artwork: {mockedCreatedArtwork}
      </Text>
    </SimpleGrid>
  );
});
