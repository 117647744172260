import { memo } from "react";
import { Slider } from "@mantine/core";

export const StoreCreatorSlider = memo(() => {
  return (
    <Slider
      value={15}
      label={null}
      color="black"
      size={2}
      thumbSize={15}
      styles={{ thumb: { borderWidth: 3, backgroundColor: "black" } }}
      mb={20}
    />
  );
});
