import { FC, forwardRef, useRef } from "react";
import { Text, Flex } from "@mantine/core";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { Icon, IconChevronDown } from "@tabler/icons-react";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

interface AnimatedScrollProps {
  container: React.MutableRefObject<HTMLDivElement | null>;
}

export const AnimatedScroll = forwardRef<HTMLDivElement, AnimatedScrollProps>(
  ({ container }, ref) => {
    const wrapper = useRef<HTMLDivElement | null>(null);
    const downIcon = useRef<Icon | null>(null);
    const text = useRef<HTMLParagraphElement | null>(null);

    return (
      <div ref={ref ? (ref as React.RefObject<HTMLDivElement>) : wrapper}>
        <Flex
          pos="fixed"
          bottom={4}
          lh={1}
          direction="column"
          align="center"
          justify="center"
          w={"100%"}
        >
          <Text fs="italic" c="gray" ref={text}>
            Scroll to discover
          </Text>
          <IconChevronDown
            ref={downIcon}
            color="gray"
            fontSize={22}
            cursor="pointer"
            className="animate-bounce mt-1"
          />
        </Flex>
      </div>
    );
  }
);

AnimatedScroll.displayName = "AnimatedScroll";
