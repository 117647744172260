import { FC, memo } from "react";
import { usePersonas } from "src/selected-personas-provider";
import { CheckboxCardElem } from "src/modules/products-slider";
import { PersonaCard } from "src/modules/personas-screens/components/persona-card";
import { PersonaDataType } from "src/models/persona";

interface PersonaElementsProps {
    openInfoModal: () => void;
}

export const PersonaElements: FC<PersonaElementsProps> = memo(({ openInfoModal }) => {
    const { personasData, setSelectedPersonaData, selectedPersonaIds, setSelectedPersonaIds } = usePersonas();

    const handlePersonaSelect = (personaId: string) => {
        if (selectedPersonaIds.includes(personaId)) {
            const filteredPersonas = selectedPersonaIds.filter((id) => id !== personaId);
            setSelectedPersonaIds(filteredPersonas);
        } else {
            setSelectedPersonaIds([...selectedPersonaIds, personaId]);
        }
    };

    const handleSearchIconClick = (personaData: PersonaDataType) => {
        openInfoModal();
        setSelectedPersonaData(personaData);
    }

    return (
        <>
            {personasData?.map((personaData) => (
                <CheckboxCardElem
                    key={personaData.persona_id}
                    id={String(personaData.persona_id)}
                    selectedIds={selectedPersonaIds}
                    name={personaData.persona_name}
                    handleSelect={handlePersonaSelect}
                    handleSearchIconClick={() => handleSearchIconClick(personaData)}
                >
                    <PersonaCard
                        key={personaData.persona_id}
                        personaData={personaData}
                    />
                </CheckboxCardElem>
            ))}
        </>
    )
});
