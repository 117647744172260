import { useState } from 'react';
import { Stepper, Button, Group, Input, TextInput } from '@mantine/core';
import TextFormatter from '../textformatter/textformatter';

interface Step {
  step: number;
  description: string;
  action: string;
}

interface StepperComponentProps {
  steps: Step[];
  callBack: (value: any) => void;
}

export function StepperComponent({ steps, callBack }: StepperComponentProps) {
  const [active, setActive] = useState(0);
  const [highestStepVisited, setHighestStepVisited] = useState(active);
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const handleStepChange = (nextStep: number) => {
    const isOutOfBounds = nextStep > steps.length || nextStep < 0;

    if (isOutOfBounds) {
      return;
    }

    setActive(nextStep);
    setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
  };
  const onSubmit = () => {
    if (input.length > 2) {
      callBack(input);
      handleStepChange(active + 1);
      setError('');
    } else {
      setError('Please enter APIKEY');
    }
  };
  const shouldAllowSelectStep = (step: number) =>
    highestStepVisited >= step && active !== step;

  return (
    <>
      <Stepper color="green" active={active} onStepClick={setActive}>
        {steps.map((step, i) => (
          <Stepper.Step
            key={step.step}
            label={step.description}
            description={step.description}
            allowStepSelect={shouldAllowSelectStep(step.step)}
          >
            {Array.isArray(step.action) ? (
              <>
                <ul className="list-decimal ">
                  {step.action.map((item) => {
                    return (
                      <li className="my-2">
                        <TextFormatter text={item} />
                      </li>
                    );
                  })}
                </ul>
                {steps.length !== 1 ? (
                  <div>
                    {steps.length - 1 === i && (
                      <TextInput
                        mt="md"
                        placeholder="Enter your access token"
                        error={error}
                        value={input}
                        onChange={(event) => {
                          setInput(event.currentTarget.value);
                          if (event.currentTarget.value.length > 2)
                            setError('');
                        }}
                      />
                    )}
                  </div>
                ) : null}
              </>
            ) : (
              step.action
            )}
          </Stepper.Step>
        ))}

        <Stepper.Completed>
          Completed, click back button to get to the previous step.
        </Stepper.Completed>
      </Stepper>

      <Group justify="center" mt="xl">
        {steps.length !== 1 ? (
          <>
            {active !== steps.length && (
              <Button
                variant="default"
                onClick={() => handleStepChange(active - 1)}
              >
                Back
              </Button>
            )}
            {active == steps.length ? (
              <Button onClick={() => handleStepChange(active)}>
                Completed
              </Button>
            ) : active < steps.length - 1 ? (
              <Button onClick={() => handleStepChange(active + 1)}>
                Next step
              </Button>
            ) : (
              <Button onClick={() => onSubmit()}>Submit</Button>
            )}
          </>
        ) : (
          <Button onClick={() => window.location.reload()}>Completed</Button>
        )}
      </Group>
    </>
  );
}
