import { FC, memo } from 'react';
import { Box } from '@mantine/core';
import { AnimatedText } from '../../../landing-screens/components/animated-text';

interface ChatMessageProps {
  message: string | JSX.Element;
  type: 'income' | 'outcome';
  index?: number;
}

export const ChatMessage: FC<ChatMessageProps> = memo(
  ({ message, type, index = 0 }) => {
    return (
      <Box
        maw={{ base: '90%', sm: '70%', md: '60%', lg: '50%' }} // Responsive max-width
        // bg={type === 'outcome' ? '#e7f1f9' : '#FEFCF0'} // Background color for message types
        style={{
          maxWidth: '70vw',
          // backgroundColor: type === 'outcome' ? '#f0f4fb' : '#fdf7e3', // Soft color variations
          color: '#333', // Neutral text color for better readability
          borderRadius: '16px', // Harmonious corner radius
          alignSelf: type === 'income' ? 'start' : 'end',
          wordBreak: 'break-word',
          // border: '1px solid #0044',
          // boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
          padding: '1.25rem 1.75rem', // Consistent padding
          marginBottom: '12px', // Even margin between boxes
          '@media (max-width: 768px)': {
            maxWidth: '85vw',
            padding: '1rem 1.25rem',
          },
          '@media (max-width: 480px)': {
            maxWidth: '90vw',
            padding: '0.75rem 1rem',
          },
        }}
        // style={{
        //   maxWidth: '70vw', // Default for larger screens
        //   backgroundColor: type === 'outcome' ? '#e7f1f9' : '#FEFCF0',
        //   color: 'black',
        //   borderRadius: '20px', // Softer radius for a modern look
        //   alignSelf: type === 'income' ? 'start' : 'end',
        //   wordBreak: 'break-word',
        //   border: '1px solid #ccc',
        //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',

        //   // Use padding that adapts better to different screen sizes
        //   padding: type === 'outcome' ? '1rem 1.5rem' : '1.5rem 2rem',

        //   marginBottom: '10px',
        //   '@media (max-width: 768px)': {
        //     maxWidth: '85vw', // Adjust for smaller screens
        //     padding: type === 'outcome' ? '0.8rem 1rem' : '1rem 1.2rem', // Reduce padding for smaller devices
        //   },
        //   '@media (max-width: 480px)': {
        //     maxWidth: '90vw', // For very small devices like phones
        //     padding: type === 'outcome' ? '0.6rem 0.8rem' : '0.8rem 1rem',
        //   },
        // }}
        // style={{
        //   borderRadius: '20px', // Softer corners for a more modern look
        //   alignSelf: type === 'income' ? 'start' : 'end',
        //   backgroundColor: type === 'outcome' ? '#e7f1f9' : '#FEFCF0',
        //   wordBreak: 'break-word',
        //   border: type === 'outcome' ? '1px solid #000' : '1px solid #000', // Subtle border to enhance visual separation
        //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Light shadow for depth
        //   padding: type === 'outcome' ? '1rem 1.5rem' : '1.5rem 2rem',
        //   marginBottom: '10px',
        //   '@media (max-width: 768px)': {
        //     maxWidth: '85vw', // Adjust for smaller screens
        //     padding: type === 'outcome' ? '0.8rem 1rem' : '1rem 1.2rem', // Reduce padding for smaller devices
        //   },
        //   '@media (max-width: 480px)': {
        //     maxWidth: '90vw', // For very small devices like phones
        //     padding: type === 'outcome' ? '0.6rem 0.8rem' : '0.8rem 1rem',
        //   },
        // }}
      >
        {typeof message === 'string' ? (
          <AnimatedText
            textSize={36}
            delay={0}
            textContent={`${message}`}
            fromColor={type === 'outcome' ? '#8b8b8b' : '#000'}
          />
        ) : (
          message // Directly render the JSX element
        )}
      </Box>
    );
  }
);
