import { useFilterContext } from "src/contexts";
import { FilterPills } from "src/modules/filter/components/filter-pill";
import { FILTER_CONFIGS } from "src/modules/filter/config";

export const ArtworksFilterPills = () => {
    const { state: filters, dispatch } = useFilterContext();

    const handleRemove = (key: string) => {
        const updatedFilters = Object.fromEntries(Object.entries(filters.artworks).filter(([k]) => k !== key));

        dispatch({
            type: 'SET_ARTWORK_FILTER',
            payload: updatedFilters
        });
    };

    return (
        <FilterPills
            filters={filters.artworks}
            filterConfigs={FILTER_CONFIGS.artworks}
            onRemove={handleRemove}
        />
    );
};
