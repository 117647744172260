import { FC, memo, ReactNode } from "react";
import { HorizontalCardsGrid } from "src/components/grids/horizontal-cards-grid";

interface ProductFormLineProps {
  title: string;
  children: ReactNode[] | ReactNode;
  className?: string;
}

export const ProductFormLine: FC<ProductFormLineProps> = memo(
  ({ title, children, className }) => {
    return <div className={`flex flex-col gap-3 h-full ${className}`}>
      <HorizontalCardsGrid title={title} controllerPosition="top" className="h-full">
        {children}
      </HorizontalCardsGrid>
    </div>;
  }
);
