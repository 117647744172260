import { FC, memo } from "react";
import { Button, Flex } from "@mantine/core";
import { useStoreContext } from "src/contexts";
import { StoreSelector } from "src/modules/store-creator/components/store-selector";

interface StoreCreatorFirstStepProps {
  onContinueClick: () => void;
}

export const StoreCreatorFirstStep: FC<StoreCreatorFirstStepProps> = memo(
  ({ onContinueClick }) => {
    const { setStoreId } = useStoreContext();

    if (!setStoreId) return null;

    return (
      <Flex
        flex="1 0 auto"
        w="100%"
        direction="column"
        justify="center"
        align="center"
      >
        <Flex
          justify="center"
          align="flex-end"
          gap={20}
          w={{ base: "80%", md: "60%" }}
        >
          <StoreSelector setStoreId={setStoreId} />
          <Button onClick={onContinueClick}>Continue</Button>
        </Flex>
      </Flex>
    );
  }
);
