import { FC, memo } from "react"
import { CheckboxCardElem } from "src/modules/products-slider";
import { useProducts } from "src/selected-products-provider";
import { ProductCard } from "src/modules/products-screens/products-list";
import { ProductDataType } from "src/models/product";

interface ProductElementsProps {
    openProductInfo: () => void;
}

export const ProductElements: FC<ProductElementsProps> = memo(({ openProductInfo }) => {
    const {
        productsData,
        setSelectedProductData,
        selectedProductIds,
        setSelectedProductIds,
    } = useProducts();

    const handleProductSelect = (productId: string) => {
        if (selectedProductIds.includes(productId)) {
            const filteredProducts = selectedProductIds.filter((id) => id !== productId);
            setSelectedProductIds(filteredProducts);
        } else {
            setSelectedProductIds([...selectedProductIds, productId]);
        }
    };

    const handleSearchIconClick = (productData: ProductDataType) => {
        openProductInfo();
        setSelectedProductData(productData);
    };

    return (
        <>
            {productsData?.map((productData) => (
                <CheckboxCardElem
                    key={productData.id}
                    id={String(productData.id)}
                    selectedIds={selectedProductIds}
                    name={productData.product_name}
                    handleSelect={handleProductSelect}
                    handleSearchIconClick={() => handleSearchIconClick(productData)}
                >
                    <ProductCard productData={productData} key={productData.id} />
                </CheckboxCardElem>
            ))}
        </>
    )
});
