import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Flex,
  Loader,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { StoreCreationFormDataType } from 'src/modules/store-creator/types/store-data';
import { updateStore } from 'src/services/automation.service';
import { useStoreContext } from 'src/contexts';

export const StoreCreatorForm = memo(() => {
  const { storeFullData } = useStoreContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const initialFormValues = {
    store_name: '',
    store_url: '',
    store_country: '',
    store_description: '',
  };

  const form = useForm<StoreCreationFormDataType>({
    initialValues: initialFormValues,
  });

  useEffect(() => {
    if (storeFullData) {
      form.setValues({
        ...initialFormValues,
        store_name: storeFullData.name || '',
        store_description: storeFullData.description || '',
      });
    }
  }, [storeFullData]);

  const handleSubmit = async (values: StoreCreationFormDataType) => {
    if (!storeFullData?.id) return;

    try {
      const accessToken = await getAccessTokenSilently();

      const updatedInfo = {
        ...storeFullData,
        store_id: storeFullData.id,
        store_name: storeFullData.name,
        store_description: values.store_description,
        store_country: storeFullData.country,
      };

      setIsSubmitting(true);
      await updateStore(accessToken, updatedInfo);
      navigate('/store-info');
    } catch (error) {
      console.error('Error updating store:', error);
    }
  };

  if (isSubmitting) {
    return (
      <Flex justify="center" align="center" h="100%">
        <Loader />
      </Flex>
    );
  }

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      gap={20}
      justify="center"
      w={{ base: '100%', lg: '70%' }}
      mx="auto"
    >
      <Flex direction="column" flex="0 0 60%">
        <Flex direction="column" gap={15} h={100}>
          <Text fw={500} fz={24} lh={1}>
            Contextual informations
          </Text>
          <Text>
            Please tell us more about your target audience and your products?
          </Text>
        </Flex>

        <textarea
          {...form.getInputProps('store_description')}
          placeholder="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt"
          style={{
            height: '100%',
            minHeight: '200px',
            width: '100%',
            padding: '20px',
            fontSize: '18px',
            fontWeight: 300,
            borderRadius: '5px',
            backgroundColor: 'inherit',
            resize: 'none',
          }}
        />
      </Flex>

      <Flex direction="column" flex="0 0 40%" justify="space-between">
        <Text fw={500} fz={24} lh={1} h={60}>
          Basic informations
        </Text>

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Box mb={20}>
            <TextInput
              label="Name of the store"
              {...form.getInputProps('store_name')}
            />
            <TextInput
              label="Website URL"
              {...form.getInputProps('store_url')}
            />
            {/* <Select
              label="Country"
              {...form.getInputProps('store_country')}
              placeholder="Country"
              data={mockedSelectData}
            /> */}
          </Box>

          <Flex gap={10}>
            <Button
              color="black"
              variant="outline"
              flex="0 0 50%"
              miw="max-content"
            >
              Connect to Printify
            </Button>
            <Button
              type="submit"
              color="grey"
              variant="outline"
              flex="0 0 50%"
              miw="max-content"
            >
              Create Store
            </Button>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
});
