import { FC, memo, PropsWithChildren } from "react";
import { AnimatedCardWrapper } from "src/modules/products-screens/products-list/components/animated-card-wrapper";
import { Blueprint } from "src/models/blueprint";

interface BlueprintCardProps {
    blueprintData: Blueprint;
}

export const BlueprintCard: FC<BlueprintCardProps & PropsWithChildren> = memo(
    ({ blueprintData, children }) => {
        return (
            <AnimatedCardWrapper>
                <div className="relative w-full pb-[100%] rounded-lg overflow-hidden">
                    <div className="absolute inset-0">
                        <img
                            src={blueprintData.images[0]}
                            alt={blueprintData.title}
                            className="h-full w-full object-cover"
                        />
                    </div>
                </div>
                {children}
            </AnimatedCardWrapper>
        );
    }
);
