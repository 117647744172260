import { UniquePersonaType } from "src/models/persona";
import { StoreBasicDataType } from "src/modules/store-creator/types/store-data";
import { FilterType } from "src/modules/filter/types";

interface BaseFilterConfig {
    key: string;
    displayName: string;
}

export interface TransformFilterConfig<T> extends BaseFilterConfig {
    pillValue: (value: T) => string;
    requestValue?: (value: T) => number;
}

type FilterConfig = BaseFilterConfig | TransformFilterConfig<StoreBasicDataType> | TransformFilterConfig<UniquePersonaType> | TransformFilterConfig<string>;

type FilterConfigs = Record<FilterType, FilterConfig[]>;

export const FILTER_CONFIGS: FilterConfigs = {
    personas: [
        { key: 'min_age', displayName: 'Min Age' },
        { key: 'max_age', displayName: 'Max Age' },
        { key: 'country', displayName: 'Country' },
        { key: 'target_audience', displayName: 'Target Audience' },
        {
            key: 'store',
            displayName: 'Store',
            pillValue: (value: StoreBasicDataType) => value.store_name,
            requestValue: (value: StoreBasicDataType) => value.store_id
        },
        {
            key: 'start_date',
            displayName: 'Start date',
            pillValue: (value: string) => new Date(value).toLocaleDateString()
        },
        {
            key: 'end_date',
            displayName: 'End date',
            pillValue: (value: string) => new Date(value).toLocaleDateString()
        }
    ],
    artworks: [
        {
            key: 'persona',
            displayName: 'Persona',
            pillValue: (value: UniquePersonaType) => value.name,
            requestValue: (value: UniquePersonaType) => value.id
        },
        { key: 'model_used', displayName: 'Model' },
        {
            key: 'start_date',
            displayName: 'Start date',
            pillValue: (value: string) => new Date(value).toLocaleDateString()
        },
        {
            key: 'end_date',
            displayName: 'End date',
            pillValue: (value: string) => new Date(value).toLocaleDateString()
        }
    ],
    products: [
        {
            key: 'collection_name',
            displayName: 'Collection'
        },
        {
            key: 'theme',
            displayName: 'Theme'
        },
        {
            key: 'persona',
            displayName: 'Persona',
            pillValue: (value: UniquePersonaType) => value.name,
            requestValue: (value: UniquePersonaType) => value.id
        },
        {
            key: 'category',
            displayName: 'Category'
        },
        {
            key: 'min_price',
            displayName: 'Min Price'
        },
        {
            key: 'max_price',
            displayName: 'Max Price'
        },
        {
            key: 'is_synced',
            displayName: 'Sync Status'
        },
        {
            key: 'store',
            displayName: 'Store',
            pillValue: (value: StoreBasicDataType) => value.store_name,
            requestValue: (value: StoreBasicDataType) => value.store_id
        },
        {
            key: 'start_date',
            displayName: 'Start Date',
            pillValue: (value: string) => new Date(value).toLocaleDateString()
        },
        {
            key: 'end_date',
            displayName: 'End Date',
            pillValue: (value: string) => new Date(value).toLocaleDateString()
        }
    ]
};
