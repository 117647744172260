import { FC, memo, useState, useEffect } from "react";
import { Slider } from "@mantine/core";
import { MotionValue } from "framer-motion";

interface SliderControlProps {
    x: MotionValue<number>;
    constraints: {
        left: number;
        right: number;
    };
    thumbSize?: number;
}

export const SliderControl: FC<SliderControlProps> = memo(({ x, constraints, thumbSize = 15 }) => {
    const [sliderValue, setSliderValue] = useState(0);
    const maxScroll = Math.abs(constraints.left);

    useEffect(() => {
        const unsubscribe = x.on("change", ((value) => {
            setSliderValue(Math.abs(value));
        }));

        return () => unsubscribe();
    }, [x]);

    return (
        <Slider
            flex="1 0 auto"
            value={sliderValue}
            onChange={(value) => x.set(-value)}
            p={10}
            min={0}
            max={maxScroll}
            label={null}
            color="black"
            size={2}
            thumbSize={thumbSize}
            styles={{
                thumb: { borderWidth: 3, backgroundColor: "black" },
            }}
        />
    );
});
