import { RefObject, useEffect, useMemo, useState } from "react";

const attempts = new Map();

export default function useOnScreen(ref: RefObject<HTMLElement>, once = false) {
  const [isIntersecting, setIntersecting] = useState(false);

  const elemKey = useMemo(() => ref.current?.id || ref.current?.innerHTML, [ref.current]);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    [ref]
  );

  useEffect(() => {
    ref.current && observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isIntersecting && !attempts.get(elemKey)) {
      attempts.set(elemKey, true);
    }
  }, [isIntersecting]);

  return once ? isIntersecting && !attempts.has(elemKey) : isIntersecting;
}
