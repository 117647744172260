import { Flex, Input } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { memo, useState } from "react";

export const Search = memo(() => {
  const [searchValue, setSearchValue] = useState("");
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Flex gap={10} align="center" pos="relative">
      <Input
        opacity={isOpened ? 1 : 0}
        value={searchValue}
        onChange={(e) => setSearchValue(e.currentTarget.value)}
        w={{ base: `${isOpened ? "75vw" : 0}`, xs: `${isOpened ? 300 : 0}` }}
        placeholder="Search something"
        style={{ transition: "all 0.3s ease" }}
      />

      <IconSearch
        cursor="pointer"
        color="grey"
        onClick={() => setIsOpened(!isOpened)}
        style={{ position: "absolute", right: 5 }}
      />
    </Flex>
  );
});
