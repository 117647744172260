import { FC, memo, ReactNode } from "react";
import { Box, Flex, Text, Image } from "@mantine/core";

interface BlueprintItemProps {
  title: string;
  colors: string[];
  bgUrl?: string;
  children?: ReactNode[] | ReactNode;
}

export const BlueprintItem: FC<BlueprintItemProps> = memo(
  ({ title, bgUrl, colors, children }) => {
    return (
      <Flex direction="column" justify="space-between">
        <Box
          pos="relative"
          mih={80}
          bg={bgUrl ? `url(${bgUrl})` : "#e0e0e0"}
          bgp="center"
          bgsz="cover"
          style={{ aspectRatio: 1 }}
        >
          <Flex gap={3} pos="absolute" bottom={5} left={5}>
            {colors.map((color, index) => (
              <Box
                key={index}
                w={10}
                h={10}
                bg={color}
                style={{ borderRadius: "50%" }}
              />
            ))}
          </Flex>
        </Box>

        <Text fz={10} c="grey" flex="1 0 auto">
          {title}
        </Text>
        {children}
      </Flex>
    );
  }
);
