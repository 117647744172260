import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { PageLoader } from './components/page-loader';
import { ProductProvider } from './selected-products-provider';
import { PrintfulProvider } from './printful-context-provider';
import { NicheProvider } from './selected-niche-provider';
import { StepperProvider } from './stepper-provider';
import { useDisclosure } from '@mantine/hooks';
import { AppShell } from '@mantine/core';
import { PersonaProvider } from './selected-personas-provider';
import { appRoutes } from './router';
import {
  getCurrentStores,
  saveUserDataInSession,
} from './services/automation.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from './modules/header';
import { FilterProvider, StoreProvider } from './contexts';
import './index.css';
import { useMutation } from '@tanstack/react-query';
import { MobileMenu, DesktopMenu } from './modules/products-screens/components/menu/components';

export const App: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();
  const { mutate: updateApiKey, isPending } = useMutation({
    mutationFn: () => {
      return updateStoreAPIKeyhandler();
    },
  });
  const location = useLocation();
  const updateStoreAPIKeyhandler = async () => {
    try {
      const token = await getAccessTokenSilently();
      const data = await saveUserDataInSession(token, user);
      if (data && 'onboarding_step' in data) {
        if (data.onboarding_step === 'user_logged') {
          navigate('/onboarding');
        } else if (data.onboarding_step === 'supplier_added') {
          navigate('/onboarding');
        } else if (
          data.onboarding_step === 'completed' &&
          location.pathname === '/'
        ) {
          navigate('/store-info');
        }
      }
    } catch (error) {
      console.error('Error updating store:', error);
    }
  };

  const [isDesktopMenuOpened, { toggle: toggleDesktopMenu }] = useDisclosure(false);
  const [isMobileMenuOpened, { toggle: toggleMobileMenu }] = useDisclosure(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 575 && isDesktopMenuOpened) {
        toggleDesktopMenu();
      }

      if (window.innerWidth >= 575 && isMobileMenuOpened) {
        toggleMobileMenu();
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [isDesktopMenuOpened, isMobileMenuOpened, toggleDesktopMenu, toggleMobileMenu]);

  useEffect(() => {
    if (isAuthenticated && user) {
      const saveUserData = async () => {
        const accessToken = await getAccessTokenSilently();
        updateApiKey();
      };

      const updateUserStores = async () => {
        const accessToken = await getAccessTokenSilently();
        await getCurrentStores(accessToken);
      };

      saveUserData();
      updateUserStores();
    }
  }, [isAuthenticated, user, getAccessTokenSilently]);

  if (isLoading || isPending) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }

  return (
    // <ModalManagerProvider>
    <PersonaProvider>
      <PrintfulProvider>
        <StoreProvider>
          <FilterProvider>
            <StepperProvider>
              <NicheProvider>
                <ProductProvider>
                  <AppShell
                    navbar={{
                      width: 180,
                      breakpoint: 0,
                      collapsed: {
                        mobile: !isMobileMenuOpened,
                        desktop: !isDesktopMenuOpened,
                      },
                    }}
                    pl={{
                      base: 0,
                      xs: isDesktopMenuOpened || location.pathname == '/' || location.pathname == '/login'
                        ? 0
                        : 30
                    }}
                    style={{ transition: 'padding 0.2s linear' }}
                  >
                    {location.pathname !== '/' &&
                      location.pathname !== '/login' && (
                        <>
                          <AppShell.Header
                            styles={{
                              header: {
                                zIndex: 50,
                                width: '100vw',
                              },
                            }}
                          >
                            <Header
                              opened={isMobileMenuOpened}
                              toggle={toggleMobileMenu}
                              withAnimation={false}
                              paddingLeft={isDesktopMenuOpened ? 180 : 0}
                            />
                          </AppShell.Header>
                          <AppShell.Navbar className='min-[576px]:p-8'>
                            <DesktopMenu opened={isDesktopMenuOpened} toggle={toggleDesktopMenu} />
                          </AppShell.Navbar>
                        </>
                      )}
                    <AppShell.Main pt={65} mih="100vh">
                      <MobileMenu opened={isMobileMenuOpened} toggle={toggleMobileMenu} />
                      {appRoutes}
                    </AppShell.Main>
                  </AppShell>
                </ProductProvider>
              </NicheProvider>
            </StepperProvider>
          </FilterProvider>
        </StoreProvider>
      </PrintfulProvider>
    </PersonaProvider>
    // </ModalManagerProvider>
  );
};
