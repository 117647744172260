import { useFilterContext } from "src/contexts";
import { FilterPills } from "src/modules/filter/components/filter-pill";
import { FILTER_CONFIGS } from "src/modules/filter/config";

export const ProductsFilterPills = () => {
    const { state: filters, dispatch } = useFilterContext();

    const handleRemove = (key: string) => {
        const updatedFilters = Object.fromEntries(Object.entries(filters.products).filter(([k]) => k !== key));

        dispatch({
            type: 'SET_PRODUCTS_FILTER',
            payload: updatedFilters
        });
    };

    return (
        <FilterPills
            filters={filters.products}
            filterConfigs={FILTER_CONFIGS.products}
            onRemove={handleRemove}
        />
    );
};
