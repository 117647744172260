import { FC, memo, MutableRefObject, useRef, useEffect } from 'react';
import { Box, Flex, Text } from '@mantine/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const messagesData = [
  { w: '15vw' },
  { w: '20vw' },
  { w: '10vw' },
  { w: '15vw' },
  { w: '15vw' },
  { w: '20vw' },
];

const horizontalRadius = 35;
const verticalRadius = 25;
const angleStep = (2 * Math.PI) / messagesData.length;

interface ThirdScreenProps {
  buttonRef: MutableRefObject<HTMLButtonElement | null>;
}

export const ThirdScreen: FC<ThirdScreenProps> = memo(({ buttonRef }) => {
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!container.current || !buttonRef.current) return;

    const ctx = gsap.context(() => {
      gsap.to(container.current, {
        scrollTrigger: {
          trigger: container.current,
          start: 'top top',
          end: 'bottom top',
          pin: true,
          pinSpacing: false,
        },
      });

      gsap.fromTo(
        '.message',
        { opacity: 0, y: '100vh' },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          ease: 'none',
          scrollTrigger: {
            trigger: container.current,
            start: 'top top',
            end: `+=200vh`,
            scrub: 2,
          },
        }
      );

      gsap.to(buttonRef.current, {
        position: 'fixed',
        scale: 1.5,
        top: '60vh',
        scrollTrigger: {
          trigger: container.current,
          end: 'center center',
          scrub: true,
        },
      });
    }, container);

    return () => {
      ctx.revert();
    };
  }, [buttonRef]);

  return (
    <Flex ref={container} h="200vh" id="third-screen">
      {/* <Flex
        w="100vw"
        h="100vh"
        justify="center"
        align="center"
        direction="column"
        fz={40}
        style={{ position: "relative" }}
      >
        {messagesData.map((msg, index) => {
          const angle = index * angleStep;
          const messageWidth = parseFloat(msg.w);
          const left = `calc(50vw + ${horizontalRadius * Math.cos(angle)}vw - ${
            messageWidth / 2
          }vw)`;
          const top = `calc(50vh + ${verticalRadius * Math.sin(angle)}vh - ${
            messageWidth / 2
          }vh)`;

          return (
            <Box
              key={index}
              className="message"
              c="black"
              w={msg.w}
              pos="absolute"
              left={left}
              top={top}
            >
              <Text>Name Subname</Text>
              <Box
                bg="rgb(216 229 249)"
                fz={16}
                p={15}
                lh={1}
                style={{ borderRadius: "5px" }}
              >
                "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa
                culpa aut repellat eaque aspernatur? Qui facilis odit natus
                architecto!"
              </Box>
            </Box>
          );
        })}
      </Flex> */}
    </Flex>
  );
});
