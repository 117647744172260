import { memo } from 'react';
import { LoadingOverlay, SimpleGrid } from '@mantine/core';
import {
  StoreDescription,
  StoreInfo,
  StorePersona,
  StoreBlueprints,
} from 'src/modules/store-info';
import { PageLayout } from 'src/components/page-layout';
import { useStoreContext } from 'src/contexts';

export const StoreInfoPage = memo(() => {
  const { isStoreReading } = useStoreContext();
  return (
    <PageLayout>
      <LoadingOverlay
        visible={isStoreReading}
        zIndex={10000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <SimpleGrid
        h="100%"
        w="100%"
        cols={{ base: 1, lg: 2 }}
        verticalSpacing={0}
        spacing={0}
        style={{ gridTemplateRows: '50% 50%', overflowX: "hidden" }}
      >
        <StoreDescription />
        <StoreInfo />
        <StorePersona />
        <StoreBlueprints />
      </SimpleGrid>
    </PageLayout>
  );
});
