import { memo, useRef } from "react";
import { Box, Divider } from "@mantine/core";
import { BenefitsBlock } from "../benefits-block";
import { SlideNumber } from "../slide-number";
import { motion, Variants } from "framer-motion";

import SecondPage4 from "../../../../../assets/Landing/SecondPage-4.avif";
import SecondPage5 from "../../../../../assets/Landing/SecondPage-5.avif";
import SecondPage3 from "../../../../../assets/Landing/SecondPage-3.png";
import SecondPage2 from "../../../../../assets/Landing/SecondPage-2.avif";
import SecondPage1 from "../../../../../assets/Landing/SecondPage-1.avif";

export const FirstSlide = memo(() => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const gridAreas = [
    { position: "first", image: SecondPage1, id: "first-main" },
    { position: "second", image: SecondPage2, id: "second-main" },
    { position: "third", image: SecondPage5, id: "third-main" },
    { position: "fourth", image: SecondPage3, id: "fourth-main" },
  ];

  // Variants for consistent animation patterns
  const gridItemVariants: Variants = {
    hidden: (index: number) => ({
      opacity: 0.5,
      scale: 1.08,
    }),
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1.2,
        ease: "easeOut",
      },
    },
  };

  const lastItemVariants: Variants = {
    hidden: {
      opacity: 0,
      x: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  return (
    <Box className="slide" w="100vw" h="100%" display="flex">
      <Divider color="black" orientation="vertical" />

      <Box
        ref={containerRef}
        flex="0 0 60%"
        h="90%"
        py={40}
        px={50}
        display="grid"
        style={{
          gap: 25,
          gridTemplateColumns: "repeat(4, 1fr)",
          gridTemplateRows: "repeat(8, 1fr)",
          gridTemplateAreas: `
            "first first second third"
            "first first second third"
            "first first second third"
            "first first second third"
            "first first second third"
            "first first fourth fourth"
            "first first fourth fourth"
            "first first fourth fourth"
          `,
        }}
      >
        {gridAreas.map((gridArea, i) => (
          <motion.div
            key={gridArea.id}
            custom={i}
            variants={
              gridArea.position === "fourth"
                ? lastItemVariants
                : gridItemVariants
            }
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-20% 0px" }}
            style={{
              gridArea: gridArea.position,
              backgroundImage: `url(${gridArea.image})`,
              backgroundSize:
                gridArea.position === "fourth" ? "contain" : "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              border: "2px solid grey",
            }}
          />
        ))}
      </Box>

      <Divider color="black" orientation="vertical" />

      <BenefitsBlock
        image={SecondPage4}
        heading="Struggling to Keep Up with POD Trends?"
        description="Saving time, predicting trends, simplifying inventory, and adapting your products to customer demand—these are just some of the challenges M-AGI-C solves in a single solution. No more guesswork or exhausting manual processes. We bring the power of AI to help you focus on what matters most: growing your brand."
      />

      <Divider color="black" orientation="vertical" />

      <SlideNumber slideNumber={1} side="left" />
    </Box>
  );
});
