import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Button } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useProducts } from "src/selected-products-provider";
import { deleteProducts } from "src/services/automation.service";

interface ProductsController {
    handleManualCreation: () => void;
}

export const ProductsController: FC<ProductsController> = (
    { handleManualCreation }
) => {
    const { selectedProductIds, setSelectedProductIds } = useProducts();

    const queryClient = useQueryClient();
    const { getAccessTokenSilently } = useAuth0();

    const handleDeleteSelected = async () => {
        const token = await getAccessTokenSilently();
        await deleteProducts(token, selectedProductIds);
        setSelectedProductIds([]);
        await queryClient.invalidateQueries({ queryKey: ["products"] });
    };

    return (
        <Flex justify="space-between">
            <Button p={0} variant="transparent" onClick={handleDeleteSelected}>
                {selectedProductIds.length !== 0 && "Delete selected"}
            </Button>

            <Button p={0} variant="transparent" onClick={handleManualCreation}>
                Manual creation of products
            </Button>
        </Flex>
    );
};
