import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, SimpleGrid } from '@mantine/core';
import { StoreInfoWrapper, StoreInfoContent, BlueprintItem } from 'src/modules/store-info/components';
import { useStoreContext } from 'src/contexts';
import { mockedBlueprintColors } from 'src/modules/mocked-data/mocked-data';
import { ProductCard } from 'src/modules/products-screens/products-list';

export const StoreBlueprints = memo(() => {
  const { storeFullData, isStoreReading } = useStoreContext();
  const navigate = useNavigate();
  const [isProductViable, setIsProductViable] = useState(false);

  useEffect(() => {
    if (storeFullData?.persona?.persona_count) {
      setIsProductViable(storeFullData?.persona?.persona_count > 0);
    } else {
      setIsProductViable(false);
    }
    return () => { };
  }, [storeFullData, isStoreReading]);

  const editBlueprints = () => {
    console.log('Edit blueprints');
  };

  const addProducts = () => {
    navigate('/products');
  };

  const renderProductContent = () => {
    // If no products exist, show the Add button view
    if (!storeFullData?.products?.product_count) {
      return (
        <div className="grid h-full place-items-center">
          <div className="flex flex-col gap-2 items-center justify-center">
            <Button
              style={{ width: 200 }}
              variant="outline"
              color="black"
              size="sm"
              disabled={!isProductViable}
              onClick={addProducts}
            >
              Add Product
            </Button>
            {!isProductViable && <div>Please add a Persona first</div>}
          </div>
        </div>
      );
    }

    // If products exist, show the products grid
    return (
      <SimpleGrid cols={2} spacing="xs" style={{ overflow: "auto" }}>
        {storeFullData?.products?.products_data?.map((product) => {
          return <ProductCard productData={product} animated={false} key={product.product_id} />;
        })}
      </SimpleGrid>
    );
  };

  return (
    <Flex direction={{ base: 'column', sm: 'row' }} gap="md">
      {/* Blueprints Section */}
      <Box flex={{ base: '0 0 auto', sm: '0 0 45%' }}>
        <StoreInfoWrapper style={{ height: '100%' }}>
          <StoreInfoContent
            title="Blue Print"
            buttonText="Edit"
            onClick={editBlueprints}
          >
            <SimpleGrid
              cols={2}
              spacing="xs"
              style={{
                overflowY: 'auto',
                maxHeight: '400px',
              }}
            >
              {storeFullData?.blueprints?.blueprint_count ? (
                storeFullData?.blueprints.blueprint_data?.map((blueprint) => (
                  <BlueprintItem
                    key={blueprint.id}
                    title={blueprint.title}
                    bgUrl={blueprint.images[0]}
                    colors={mockedBlueprintColors}
                  />
                ))
              ) : (
                <div>No blueprints available</div>
              )}
            </SimpleGrid>
          </StoreInfoContent>
        </StoreInfoWrapper>
      </Box>

      {/* Products Section */}
      <StoreInfoWrapper style={{ flex: '1 0 auto' }}>
        <StoreInfoContent
          title="Product"
          buttonText="Add"
          onClick={() => {
            if (isProductViable) {
              addProducts();
            }
          }}
        >
          {renderProductContent()}
        </StoreInfoContent>
      </StoreInfoWrapper>
    </Flex>
  );
});