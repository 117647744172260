export class WebSocketClient {
  public webSocketInstance: WebSocket;

  constructor() {
    const wsUrl = process.env.REACT_APP_API_WS_SERVER_URL as string;
    if (!wsUrl) {
      throw new Error('WebSocket URL is not defined');
    }
    
    this.webSocketInstance = new WebSocket(wsUrl);
    
    this.webSocketInstance.onopen = () => {
      console.log('WebSocket connected');
      this.sendMessage({ action: "RecieveMessage" });
    };

    this.webSocketInstance.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.webSocketInstance.onclose = () => {
      console.log('WebSocket closed');
    };
  }

  public sendMessage(message: any): void {
    if (this.webSocketInstance.readyState === WebSocket.OPEN) {
      this.webSocketInstance.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not connected');
    }
  }

  public disconnect(): void {
    if (this.webSocketInstance) {
      this.webSocketInstance.close();
    }
  }
}