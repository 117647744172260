import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WebSocketContext } from './modules/websocket-client';
import theme from '../src/theme';

const queryClient = new QueryClient();

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

root.render(
  <BrowserRouter>
    <WebSocketContext>
      <MantineProvider theme={theme}>
        <Auth0ProviderWithNavigate>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Auth0ProviderWithNavigate>
      </MantineProvider>
    </WebSocketContext>
  </BrowserRouter>
);
