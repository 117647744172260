import { FC, memo, ReactNode, useEffect, useState } from "react";
import { AnimatedCardWrapper } from "src/modules/products-screens/products-list/components/animated-card-wrapper";
import { ArtworkDataType } from "src/modules/products-screens/types/artwork-data";

interface ArtworkCardProps {
  artworkData: ArtworkDataType;
  children?: ReactNode;
}

export const ArtworkCard: FC<ArtworkCardProps> = memo(
  ({ artworkData, children }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const img = new Image();
      img.src = artworkData.image_url;
      img.onload = () => setIsLoading(false);
    }, [artworkData.image_url]);

    return (
      <AnimatedCardWrapper>
        <div className="relative w-full pb-[100%] rounded-lg overflow-hidden">
          <div className="absolute inset-0">
            {isLoading && (
              <div className="h-full w-full bg-gray-300 animate-pulse" />
            )}
            <img
              src={artworkData.image_url}
              alt={`Artwork ${artworkData.artwork_id}`}
              draggable={false}
              className="h-full w-full object-cover"
            />
          </div>
        </div>
        {children}
      </AnimatedCardWrapper>
    );
  }
);
