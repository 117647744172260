import { FC, memo, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Flex } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

interface SearchIconProps {
    onClick: () => void;
}

export const SearchIcon: FC<SearchIconProps> = memo(({ onClick }) => {
    const details = useRef<HTMLDivElement | null>(null);

    useGSAP(
        () => {
            const animation = gsap.to(details.current, {
                scale: 1.2,
                paused: true,
            });

            details.current?.addEventListener("mouseenter", () => animation.play());
            details.current?.addEventListener("mouseleave", () =>
                animation.reverse()
            );
        },
        {
            scope: details
        }
    );

    return <Flex
        ref={details}
        justify="center"
        align="center"
        w={25}
        h={25}
        bg="white"
        style={{
            borderRadius: "50%",
            cursor: "pointer",
        }}
    >
        <IconSearch
            size={16}
            color="black"
            onClick={onClick}
        />
    </Flex>
});
