import { memo, useState, useEffect, useRef } from 'react';
import { Input } from '@mantine/core';
import { IconCircleArrowUpFilled } from '@tabler/icons-react';
import './ChatInput.css'; // Import the CSS file for animations

export const ChatInput = memo(
  ({
    onSendMessage,
    animateFocus = false,
  }: {
    onSendMessage: (message: string) => void;
    animateFocus?: boolean;
  }) => {
    const [searchMessage, setSearchMessage] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input field
    const [focusAnimation, setFocusAnimation] = useState(false); // State to manage the focus animation

    const handleSendMessage = () => {
      if (searchMessage.trim() === '') return; // Prevent sending empty messages
      onSendMessage(searchMessage); // Pass the message to the parent component
      setSearchMessage(''); // Clear the input field
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // Prevent default Enter key behavior
        handleSendMessage(); // Call the send message function
      }
    };

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus(); // Keep the input focused after sending a message
      }
    }, [searchMessage]);

    useEffect(() => {
      if (animateFocus) {
        setFocusAnimation(true); // Trigger the animation
        inputRef.current?.focus(); // Shift keyboard focus to the input field

        const timer = setTimeout(() => {
          setFocusAnimation(false); // Reset animation state after 0.5 seconds
        }, 1000);

        return () => clearTimeout(timer); // Cleanup timer on unmount or prop change
      }
      return void 0;
    }, [animateFocus]);

    return (
      <Input
        ref={inputRef} // Attach the ref to the Input component
        value={searchMessage}
        onChange={(e) => setSearchMessage(e.target.value)}
        onKeyDown={handleKeyDown} // Handle keyboard events
        size="xl"
        placeholder="Can you show me all the different products for young people that I created last month?"
        className={focusAnimation ? 'input-focus-animation' : ''} // Apply animation class conditionally
        rightSection={
          <span>
            <IconCircleArrowUpFilled
              strokeWidth={0}
              width={30}
              height={30}
              style={{
                transition: 'background-color 0.2s ease, color 0.2s ease',
                cursor: 'pointer',
              }}
              onClick={handleSendMessage} // Call the send message function on icon click
            />
          </span>
        }
      />
    );
  }
);
