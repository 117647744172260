import { FC, useState, useEffect } from "react";

interface BlueprintInfoElemProps {
  title: string;
  changePrice: (value: string) => void;
  changeQuantity: (value: string) => void;
}

export const BlueprintInfoElem: FC<BlueprintInfoElemProps> = ({
  title,
  changePrice,
  changeQuantity
}) => {
  const [basePrice, setBasePrice] = useState<string>("");
  const [finalPrice, setFinalPrice] = useState<string>("0");

  const calculateFinalPrice = (base: string) => {
    const numericBase = Number(base);
    if (!isNaN(numericBase)) {
      const withMarkup = numericBase * 1.5; // 50% markup
      setFinalPrice(withMarkup.toFixed(2));
      changePrice(withMarkup.toString());
    }
  };

  const handlePriceChange = (value: string) => {
    setBasePrice(value);
    calculateFinalPrice(value);
  };

  return (
    <>
      <h2 className="text-xs text-gray-500 font-normal text-nowrap overflow-hidden truncate w-full max-w-52">{title}</h2>

      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <label className="text-xs text-gray-500 whitespace-nowrap">
            Price
          </label>
          <div className="relative w-20">
            <input
              type="text"
              placeholder="25"
              value={basePrice}
              onChange={(e) => handlePriceChange(e.target.value)}
              className="w-full bg-transparent px-2 text-sm border rounded-md border-gray-400 outline-none placeholder:text-gray-300"
            />
            <span className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500">
              $
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between text-xs text-gray-500">
          <span>Benefits per sell</span>
          <span className="text-gray-400">{finalPrice}$</span>
        </div>

        <div className="flex items-center justify-between gap-2">
          <label className="text-xs text-gray-500 whitespace-nowrap">
            Quantity of artworks
          </label>
          <input
            type="text"
            placeholder="2"
            onChange={(e) => changeQuantity(e.target.value)}
            className="w-16 bg-transparent px-2 text-sm border rounded-md border-gray-400 outline-none placeholder:text-gray-300"
          />
        </div>
      </div>
    </>
  );
};
