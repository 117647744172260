// src/theme.ts
import { generateColors } from '@mantine/colors-generator';
import { Paper, Image, SimpleGrid, createTheme, Text } from '@mantine/core';

const theme = createTheme({
  primaryColor: 'custom-dark-grey',
  white: '#fefcf0',
  black: '#2f2f31',
  components: {
    Paper: Paper.extend({
      classNames: {
        root: 'my-root-class',
      },
    }),
    Image: Image.extend({
      classNames: {
        root: 'my-root-class',
      },
    }),
    SimpleGrid: SimpleGrid.extend({
      classNames: {
        root: 'my-root-class',
      },
      styles: {
        root: { height: '100%' },
      },
    }),
    Text: Text.extend({
      styles: {
        root: {
          color: '#2f2f31',
        },
      },
    }),
  },
  fontFamily: 'Poppins, sans-serif',
  colors: {
    'custom-white': generateColors('#fefcf0'),
    'custom-dark-grey': generateColors('#939598'),
    'custom-medium-grey': generateColors('#d9dddc'),
    'custom-light-grey': generateColors('#edefee'),
    'custom-black': generateColors('#2f2f31'),
    'custom-yellow': generateColors('#e7f85b'),
  },
});

export default theme;
