import { AxiosRequestConfig } from 'axios';
import { ApiResponse } from '../models/api-response';
import { callExternalApi } from './external-api.service';
import Persona from 'src/models/persona';
import { axiosClient } from './axios-client';
import { ProductTypes } from '@/models/product-types';
import { Blueprint, SelectedBlueprintType } from '@/models/blueprint';
import { StoreFullDataType } from 'src/modules/store-creator/types/store-data';
import { SavedProductData, ProductDataType } from 'src/models/product';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const createPersona = async (
  accessToken: string,
  storeId: number,
  quantity = '2',
  user_guidance?: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/persona/create-persona`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: JSON.stringify({
      store_id: storeId,
      quantity: quantity,
      user_guidance: user_guidance,
    }),
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const savePersonas = async (
  accessToken: string,
  storeId: number,
  personas: any[],
  selectedPersona: string[]
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/persona/save-persona`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      store_id: storeId,
      personas: personas,
      selected_persona: selectedPersona
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const filterPersonas = async (
  accessToken: string,
  filterQuery: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/persona/filter_persona?${filterQuery}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const filterArtworks = async (
  accessToken: string,
  filterQuery: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/artwork/filter-artwork?${filterQuery}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const filterProducts = async (
  accessToken: string,
  filterQuery: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/products/filter_products?${filterQuery}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const createProducts = async (
  accessToken: string,
  blueprintIds: SelectedBlueprintType[],
  personaIds: number[],
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/product/create-product`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data:{
      blueprint_ids: blueprintIds,
      persona_ids: personaIds,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getProducts = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/product/all-products`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getProductData = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/product-data`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const saveProducts = async (
  accessToken: string,
  selectedProducts: SavedProductData[]
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/product/save-products`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: selectedProducts
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const publishProducts = async (
  accessToken: string,
  productIds: number[],
  storeId: number

): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/product/publish-product`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      product_ids: productIds,
      store_id: storeId
    }
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getCollectionsList = async (accessToken: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/filter/collection-name`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getUniqueThemesList = async (accessToken: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/filter/unique-theme`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getUniqueCategoriesList = async (accessToken: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/filter/unique-category`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};


export const getModelsList = async (accessToken: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/filter/model-used`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getUniquePersonasList = async (accessToken: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/filter/unique-persona`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getUniqueCountriesList = async (accessToken: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/filter/unique-country`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getCountriesList = async (): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/country`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getPersonasData = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/persona/all-persona`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const refreshDatabase = async (
  accessToken: string,
  callback: (artwork: any) => void
): Promise<void> => {
  try {
    const response = await fetch(`${apiServerUrl}/api/refresh-database`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      const processChunk = (chunk: Uint8Array) => {
        buffer += decoder.decode(chunk, { stream: true });
        const lines = buffer.split('\n\n\n');
        const lastLine = lines.pop(); // Last line might be incomplete
        buffer = lastLine !== undefined ? lastLine : ''; // Save it for later, or default to empty string if undefined
        lines.forEach((line) => {
          if (line) {
            // replace single quotes with double quotes
            const regex =
              /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
            line = line.replace(regex, '"');
            let trend = {};
            try {
              console.log(line);
              trend = JSON.parse(line);
            } catch (e) {
              console.log(e);
              trend = JSON.parse(JSON.stringify(line));
            }
            callback(trend); // Process each complete JSON object
          }
        });
      };

      const read = async () => {
        const { done, value } = await reader.read();
        if (done) {
          // Process any remaining text in the buffer
          if (buffer) {
            processChunk(new Uint8Array());
          }
          reader.releaseLock();
          return;
        }
        processChunk(value);
        read(); // Recursive call to process the next chunk
      };

      read(); // Start processing
    }
    // If all the trends have been processed (might be lower than the number of the request because some will be filtered, find a way to check), call the callbackDone function
  } catch (e) {
    console.error('Error fetching created:', e);
    // Handle errors or call the callback with an error if needed
  }
};

export const syncProductsOnAmazon = async (
  accessToken: string,
  selectedProducts: ProductDataType[],
  callback: (artwork: any) => void
): Promise<void> => {
  try {
    const response = await fetch(`${apiServerUrl}/api/sync-product-templates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        product_template_ids: selectedProducts.map((p) => p.id),
      }),
    });

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      const processChunk = (chunk: Uint8Array) => {
        buffer += decoder.decode(chunk, { stream: true });
        const lines = buffer.split('\n\n\n');
        const lastLine = lines.pop(); // Last line might be incomplete
        buffer = lastLine !== undefined ? lastLine : ''; // Save it for later, or default to empty string if undefined
        lines.forEach((line) => {
          if (line) {
            // replace single quotes with double quotes
            const regex =
              /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
            line = line.replace(regex, '"');
            let trend = {};
            try {
              console.log(line);
              trend = JSON.parse(line);
            } catch (e) {
              console.log(e);
              trend = JSON.parse(JSON.stringify(line));
            }
            callback(trend); // Process each complete JSON object
          }
        });
      };

      const read = async () => {
        const { done, value } = await reader.read();
        if (done) {
          // Process any remaining text in the buffer
          if (buffer) {
            processChunk(new Uint8Array());
          }
          reader.releaseLock();
          return;
        }
        processChunk(value);
        read(); // Recursive call to process the next chunk
      };

      read(); // Start processing
    }
    // If all the trends have been processed (might be lower than the number of the request because some will be filtered, find a way to check), call the callbackDone function
  } catch (e) {
    console.error('Error fetching created:', e);
    // Handle errors or call the callback with an error if needed
  }
};

export const getTrendsFromNiches = async (
  accessToken: string,
  selectedNiches: string[],
  callback: (trend: any) => void,
  callbackDone: (num: number) => void
): Promise<void> => {
  try {
    const response = await fetch(`${apiServerUrl}/api/get-tiktok-hashtags`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        niches: selectedNiches,
      }),
    });

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      const processChunk = (chunk: Uint8Array) => {
        buffer += decoder.decode(chunk, { stream: true });
        const lines = buffer.split('\n\n\n');
        const lastLine = lines.pop(); // Last line might be incomplete
        buffer = lastLine !== undefined ? lastLine : ''; // Save it for later, or default to empty string if undefined
        lines.forEach((line) => {
          if (line) {
            // replace single quotes with double quotes
            const regex =
              /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
            line = line.replace(regex, '"');
            let trend = {};
            try {
              trend = JSON.parse(line);
            } catch (e) {
              console.log(e);
              trend = JSON.parse(JSON.stringify(line));
            }
            callback(trend); // Process each complete JSON object
          }
        });
      };

      const read = async () => {
        const { done, value } = await reader.read();
        if (done) {
          // Process any remaining text in the buffer
          if (buffer) {
            processChunk(new Uint8Array());
          }
          reader.releaseLock();
          return;
        }
        processChunk(value);
        read(); // Recursive call to process the next chunk
      };

      read(); // Start processing
    }
    // If all the trends have been processed (might be lower than the number of the request because some will be filtered, find a way to check), call the callbackDone function
  } catch (e) {
    console.error('Error fetching trends:', e);
    // Handle errors or call the callback with an error if needed
  }
};

export const getPersonas = async (
  accessToken: string,
  selectedTrend: any,
  selectedCountry: any
): Promise<ApiResponse> => {
  try {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/get-persona`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        trends: selectedTrend,
        country: selectedCountry,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;
    return { data, error };
  } catch (e: any) {
    return { data: null, error: e.message };
  }
};

export const getUserSuppliers = async (
  accessToken: string
): Promise<ApiResponse> => {
  try {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/supplier-data`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;
    return { data, error };
  } catch (e: any) {
    return { data: null, error: e.message };
  }
};

export const getCollectionsFromTrends = async (
  accessToken: string,
  selectedTrends: any[]
): Promise<ApiResponse> => {
  try {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/make-textual-artwork`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        trends: selectedTrends,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;
    return { data, error };
  } catch (e: any) {
    return { data: null, error: e.message };
  }
};

export const getArtworksFromTrends = async (
  accessToken: string,
  selectedTrends: any[]
): Promise<ApiResponse> => {
  try {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/make-image-artwork`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        trends: selectedTrends,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;
    return { data, error };
  } catch (e: any) {
    return { data: null, error: e.message };
  }
};

export const createProductTemplates = async (
  accessToken: string,
  selectedArtworks: any[],
  supliers: string[],
  callback: (artwork: any) => void
): Promise<void> => {
  try {
    const response = await fetch(
      `${apiServerUrl}/api/create-product-templates`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          image_urls: selectedArtworks,
          suppliers: supliers,
        }),
      }
    );

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      const processChunk = (chunk: Uint8Array) => {
        buffer += decoder.decode(chunk, { stream: true });
        const lines = buffer.split('\n\n\n');
        const lastLine = lines.pop(); // Last line might be incomplete
        buffer = lastLine !== undefined ? lastLine : ''; // Save it for later, or default to empty string if undefined
        lines.forEach((line) => {
          if (line) {
            // replace single quotes with double quotes
            const regex =
              /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
            line = line.replace(regex, '"');
            let trend = {};
            try {
              console.log(line);
              trend = JSON.parse(line);
            } catch (e) {
              console.log(e);
              trend = JSON.parse(JSON.stringify(line));
            }
            callback(trend); // Process each complete JSON object
          }
        });
      };

      const read = async () => {
        const { done, value } = await reader.read();
        if (done) {
          // Process any remaining text in the buffer
          if (buffer) {
            processChunk(new Uint8Array());
          }
          reader.releaseLock();
          return;
        }
        processChunk(value);
        read(); // Recursive call to process the next chunk
      };

      read(); // Start processing
    }
    // If all the trends have been processed (might be lower than the number of the request because some will be filtered, find a way to check), call the callbackDone function
  } catch (e) {
    console.error('Error fetching created:', e);
    // Handle errors or call the callback with an error if needed
  }
};

export const updateAttributes = async (
  accessToken: string,
  selectedProducts: ProductDataType[]
): Promise<ApiResponse> => {
  try {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/update-amazon-attributes`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        ids: selectedProducts.map((p) => p.id),
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;
    return { data, error };
  } catch (e: any) {
    return { data: null, error: e.message };
  }
};

export const getTargetAudienceList = async (accessToken: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/filter/target-audience`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getAudiences = async (
  accessToken: string,
  selectedPersona: Persona
): Promise<ApiResponse> => {
  try {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/get-target-audiences`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        trends: selectedPersona.persona_trends,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;
    return { data, error };
  } catch (e: any) {
    return { data: null, error: e.message };
  }
};

export const getArtworks = async (
  accessToken: string
): Promise<ApiResponse> => {
  try {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/artwork/all-artwork`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;
    return { data, error };
  } catch (e: any) {
    return { data: null, error: e.message };
  }
};

export const startArtworksGeneration = async (
  accessToken: string,
  targetAudience: any,
  psdTemplate: string,
  model: string
) => {
  try {
    const response = await fetch(`${apiServerUrl}/api/artwork`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        target_audiences: [targetAudience],
        psd_template: psdTemplate,
        image_model: model,
      }),
    });

    return {
      ...(await response.json()),
      targetAudience: {
        group_name: targetAudience.group_name,
        group_identified_queries: targetAudience.group_identified_queries,
        group_description: targetAudience.group_description,
        limit: targetAudience.limit,
      },
      personaname: targetAudience.personaname,
    };
  } catch (error) {
    console.error('Error fetching trends: ', error);
  }
};

export const deleteArtwork = async (
  accessToken: string,
  artwork_ids: string[]
): Promise<{ message: string }> => {
  try {
    const response = await fetch(`${apiServerUrl}/api/artwork/delete-artwork`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ artwork_ids: artwork_ids }),
    });

    return response.json();
  } catch (error) {
    console.error('Error deleting artwork: ', error);
    throw error; // Re-throw the error or
    // return { message: 'Failed to delete artwork' }; // Return an error message
  }
};

export const getArtworksFromAudiences = async (
  accessToken: string,
  targetAudiences: any[],
  psdTemplate: string,
  model: string,
  callback: (artwork: any) => void
): Promise<void> => {
  try {
    const response = await fetch(`${apiServerUrl}/api/get-artwork`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        target_audiences: targetAudiences,
        psd_template: psdTemplate,
        image_model: model,
      }),
    });

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      const processChunk = (chunk: Uint8Array) => {
        buffer += decoder.decode(chunk, { stream: true });
        const lines = buffer.split('\n\n\n');
        const lastLine = lines.pop(); // Last line might be incomplete
        buffer = lastLine !== undefined ? lastLine : ''; // Save it for later, or default to empty string if undefined
        lines.forEach((line) => {
          if (line) {
            // replace single quotes with double quotes
            const regex =
              /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
            line = line.replace(regex, '"');
            let trend = {};
            try {
              console.log(line);
              trend = JSON.parse(line);
            } catch (e) {
              console.log(e);
              trend = JSON.parse(JSON.stringify(line));
            }
            callback(trend); // Process each complete JSON object
          }
        });
      };

      const read = async () => {
        const { done, value } = await reader.read();
        if (done) {
          // Process any remaining text in the buffer
          if (buffer) {
            processChunk(new Uint8Array());
          }
          reader.releaseLock();
          return;
        }
        processChunk(value);
        read(); // Recursive call to process the next chunk
      };

      read(); // Start processing
    }
    // If all the trends have been processed (might be lower than the number of the request because some will be filtered, find a way to check), call the callbackDone function
  } catch (e) {
    console.error('Error fetching trends:', e);
    // Handle errors or call the callback with an error if needed
  }
};

export const deleteProducts = async (
  accessToken: string,
  product_ids: string[]
): Promise<ApiResponse> => {
  try {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/product/delete-products`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        products_ids: product_ids,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;
    return { data, error };
  } catch (e: any) {
    return { data: null, error: e.message };
  }
};

export const deletePersonas = async (
  accessToken: string,
  personaIds: string[]
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/persona/delete-persona`,
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: JSON.stringify({
      persona_ids: personaIds,
    }),
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;
  return { data, error };
};

export const getPsdArtworkTemplates = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/get-psd-artwork-templates`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getGoogleTrendsDaily = async (
  accessToken: string,
  country: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/get-google-trends-daily`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      country: country,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getGoogleTrendsQueryData = async (
  accessToken: string,
  query: string,
  country: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/get-google-trends-query-data`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      keyword: query,
      country: country,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const filterGeneralTrends = async (
  accessToken: string,
  trends: any[],
  country: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/filter-general-trends`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      trends: trends,
      country: country,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getAudienceGroups = async (
  accessToken: string,
  trend: any
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/get-audience-groups`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      trend: trend,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getCurrentStores = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/store/save-store`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getUserStores = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/store/all-stores`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const readStore = async (
  accessToken: string,
  storeId: number
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/store/read-store`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: JSON.stringify({
      store_id: storeId,
    }),
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const updateStore = async (
  accessToken: string,
  storeUpdatedInfo: StoreFullDataType
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/store/update-store`,
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: JSON.stringify(storeUpdatedInfo),
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const saveStore = async (
  data: {
    store_id: string;
    store_name: string;
    default_provider: string;
  },
  accessToken: string
) => {
  try {
    const response = await axiosClient.post('/save_store', data, {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBlueprintsData = async (
  accessToken: string,
  page?: number,
  perPage?: number
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/blueprints/all-blueprints?page=${page || 1}&per_page=${perPage || 10}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const generateBlueprints = async (
  data: {
    product_type: ProductTypes;
  },
  accessToken: string
) => {
  try {
    const response = await axiosClient.post<Array<Blueprint>>(
      '/printify/blueprints',
      data,
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const printProviders = async (
  data: {
    blueprint_ids: number[];
  },
  accessToken: string
) => {
  try {
    const response = await axiosClient.post<
      Array<{ id: string; title: string }>
    >('/printify/print-providers', data, {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveWaitlistData = async (data: {
  name: string;
  email: string;
  phone?: string;
  businessWebsite?: string;
  businessModel: string;
  platforms: string[];
  businessGrowth: string;
  challenges: string;
  timeSpent: string;
  features: string[];
  importanceOfFeatures: Record<string, number>;
  integrations: string[];
  likelihoodOfSwitching: string;
  currentMethods: string[];
  satisfaction: string;
  trendAnalysisImprovements: string[];
  support: string;
  excitingFeatures: string[];
  interestInCaseStudies: string;
  additionalComments?: string;
  otherBusinessModel?: string;
  otherPlatform?: string;
  otherChallenge?: string;
  otherIntegration?: string;
}): Promise<void> => {
  try {
    console.log(data);
    const response = await fetch(
      `${apiServerUrl}/api/user/save-user-waitlist-data`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to save waitlist data');
    }
  } catch (e) {
    console.error('Error saving waitlist data:', e);
  }
};

interface SaveUserDataResponse {
  message: string;
  user_email: string;
  status: string;
  details: string;
  onboarding_step: string;
}

export const saveUserDataInSession = async (
  accessToken: string,
  userData: any
): Promise<SaveUserDataResponse | void> => {
  try {
    const response = await fetch(`${apiServerUrl}/api/user/save-user-data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ user_details: userData }),
    });

    if (!response.ok) {
      throw new Error('Failed to save user data in session');
    }
    return response.json() as Promise<SaveUserDataResponse>;
  } catch (e) {
    console.error('Error saving user data in session:', e);
  }
};
