import { FC, memo } from "react";
import { Flex, Select } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { FilterDate } from "src/modules/filter/components/filter-date";
import { getModelsList, getUniquePersonasList } from "src/services/automation.service";
import { UniquePersonaType } from "src/models/persona";
import { FilterDateType } from "src/modules/products-screens/types/filter-values";
import { ArtworkFiltersType } from "src/modules/filter/types";

interface ArtworksFilterProps {
    newFilters: ArtworkFiltersType;
    setNewFilters: (newFilters: ArtworkFiltersType) => void;
}

export const ArtworksFilter: FC<ArtworksFilterProps> = memo(({ newFilters, setNewFilters }) => {
    const { getAccessTokenSilently } = useAuth0();

    const { data: personasList } = useQuery({
        queryKey: ['personasList'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const personas: UniquePersonaType[] = (await getUniquePersonasList(token)).data || [];

            return personas;
        },
    });

    const { data: modelsList } = useQuery({
        queryKey: ['modelsList'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const models: string[] = (await getModelsList(token)).data || [];

            return models;
        },
    });

    const personasSelectorData = personasList?.map((persona) => ({
        value: String(persona.id),
        label: persona.name,
    }));

    const modelsSelectorData = modelsList?.map((model) => ({
        value: model,
        label: model,
    }));

    const selectedDate = {
        fromDate: newFilters.start_date ? new Date(newFilters.start_date) : null,
        toDate: newFilters.end_date ? new Date(newFilters.end_date) : null
    };

    const handlePersonaSelect = ((value: string | null) => {
        const persona = personasList?.find(({ id }) => id === Number(value));

        setNewFilters({
            ...newFilters,
            persona: persona
        });
    });

    const handleModelChange = ((value: string | null) => {
        setNewFilters({
            ...newFilters,
            model_used: value || ""
        });
    });

    const handleDateChange = (dateValue: FilterDateType) => {
        setNewFilters({
            ...newFilters,
            start_date: dateValue.fromDate?.toISOString().split('T')[0],
            end_date: dateValue.toDate?.toISOString().split('T')[0]
        });
    };

    return (
        <Flex direction="column" gap={15}>
            <Select
                label="Persona"
                placeholder="Persona"
                defaultValue={newFilters.persona?.name || ""}
                onChange={handlePersonaSelect}
                data={personasSelectorData}
            />

            <Select
                label="Model Used"
                placeholder="Model"
                value={newFilters.model_used || ""}
                onChange={handleModelChange}
                data={modelsSelectorData || []}
            />

            <FilterDate
                selectedDate={selectedDate}
                setSelectedDate={handleDateChange}
            />
        </Flex>
    );
});