import { memo } from "react";
import { Box, Divider, Flex } from "@mantine/core";
import { AnimatedText } from "../../../components";
import { SlideNumber } from "../slide-number";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import firstImage from "../../../../../assets/Landing/ThirdPage-1.avif";
import secondImage from "../../../../../assets/Landing/ThirdPage-2.avif";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const SecondSlide = memo(() => {
  return (
    <Box className="slide" w="100vw" h="100%" display="flex">
      <Flex
        direction="column"
        align="center"
        justify="center"
        gap={30}
        p={40}
        flex="0 0 30%"
      >
        <Box
          w="45vh"
          h="65vh"
          style={{
            backgroundImage: `url(${firstImage})`,
            backgroundSize: "cover",
            backgroundPosition: "right",
          }}
        ></Box>
        <Box>
          <div className="ml-7 mt-8">
            <AnimatedText
              fw={500}
              textSize={18}
              speed={5000}
              textContent="With M-AGI-C, managing your print-on-demand operations becomes effortless and highly effective. Connect your store, and let our AI analyze market trends, automatically generate captivating designs, and optimize your stock and pricing—all in just one click, with zero logistical worries."
            />
          </div>
        </Box>
      </Flex>

      <Divider color="black" orientation="vertical" />
      <div className="w-[5%]"></div>
      <Flex
        direction="column"
        align="flex-end"
        justify="space-between"
        flex="0 0 65%"
        pos="relative"
      >
        <Flex justify="space-between" w="90%" pt={40} pr={70} gap={20}>
          <Box>
            <AnimatedText
              fw={500}
              textSize={38}
              speed={1000}
              w="70%"
              textContent="Transform Your POD Business with Seamless AI Integration"
            />
          </Box>
        </Flex>
        <Box
          w="90%"
          h="75%"
          style={{
            backgroundImage: `url(${secondImage})`,
            backgroundSize: "cover",
            backgroundPosition: "right",
            alignSelf: "flex-end",
          }}
        ></Box>
      </Flex>

      <Divider color="black" orientation="vertical" />

      <SlideNumber slideNumber={2} side="right" color="light" />
    </Box>
  );
});
