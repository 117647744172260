import { PersonaFiltersType, ArtworkFiltersType, ProductFilterType } from "src/modules/filter/types";

export const initialPersonaFilters: PersonaFiltersType = {
    min_age: 0,
    max_age: 0,
    country: "",
    target_audience: "",
    store: {
        store_id: 0,
        store_name: ""
    },
    start_date: "",
    end_date: "",
};

export const initialArtworkFilters: ArtworkFiltersType = {
    persona: {
        id: 0,
        name: ""
    },
    model_used: "",
    start_date: "",
    end_date: "",
};

export const initialProductFilters: ProductFilterType = {
    collection_name: "",
    theme: "",
    persona: {
        id: 0,
        name: "",
    },
    category: "",
    min_price: 0,
    max_price: 0,
    is_synced: false,
    store: {
        store_id: 0,
        store_name: ""
    },
    start_date: "",
    end_date: "",
};
