import { FC } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import { PageLayout } from "src/components/page-layout";
import { HorizontalCardsGrid } from "src/components/grids/horizontal-cards-grid";
import { CreatedProductCard } from "src/modules/product-creation-form";
import { saveProducts, publishProducts } from "src/services/automation.service";
import { useProducts } from "src/selected-products-provider";

interface CreatedProductsProps {
    resetState: () => void;
}

export const CreatedProducts: FC<CreatedProductsProps> = ({ resetState }) => {
    const { storeId, savedProducts, productsToPublish, createdProducts } = useProducts();

    const queryClient = useQueryClient();
    const { getAccessTokenSilently } = useAuth0();

    const handleClick = async () => {
        resetState();

        const token = await getAccessTokenSilently();

        await saveProducts(token, savedProducts);
        await queryClient.invalidateQueries({ queryKey: ["products"] });

        if (storeId && productsToPublish.length > 0) {
            await publishProducts(token, productsToPublish, storeId);
        }
    };

    return (
        <PageLayout>
            <div className="h-full p-5">
                <HorizontalCardsGrid
                    className="h-full"
                    footer={
                        <button
                            onClick={handleClick}
                            className="w-max ml-auto px-4 py-2 border bg-transparent hover:bg-black hover:text-white border-black rounded-md text-xs font-medium cursor-pointer flex-shrink-0 transition-colors duration-300">
                            {productsToPublish.length > 0 ? "Publish products" : "My products"}
                        </button>
                    }
                >
                    {createdProducts.map((productData, index) => (
                        <CreatedProductCard
                            key={index}
                            productData={productData}
                        />
                    ))}
                </HorizontalCardsGrid>
            </div>
        </PageLayout>
    );
};
