import { FC, memo, ReactElement } from 'react';
import { Button, Flex, Text } from '@mantine/core';

interface StoreInfoContentProps {
  title: string;
  buttonText: string;
  onClick: () => void;
  children: ReactElement | ReactElement[];
}

export const StoreInfoContent: FC<StoreInfoContentProps> = memo(
  ({ title, buttonText, onClick, children }) => {
    return (
      <Flex direction="column" w="100%" h="100%" gap={20}>
        <Flex justify="space-between">
          <Text fz={24} fw={500}>
            {title}
          </Text>
          <Button variant="transparent" onClick={onClick} p={0}>
            {buttonText}
          </Button>
        </Flex>
        {children}
      </Flex>
    );
  }
);
