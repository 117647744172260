import { memo, useRef } from 'react';
import { Grid, Image } from '@mantine/core';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { AnimatedText } from '../../../components';

import firstImage from '../../../../../assets/Landing/FirstPage-1.jpg';
import secondImage from '../../../../../assets/Landing/FirstPage-2.jpg';
import thirdImage from '../../../../../assets/Landing/FirstPage-4.jpg';
import fifthImage from '../../../../../assets/Landing/FirstPage-5.png';
import fourthImage from '../../../../../assets/Landing/FirstPage-3.png';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const InitialSlide = memo(() => {
  const container = useRef<HTMLDivElement | null>(null);

  const tl = useRef<GSAPTimeline | null>(null);

  useGSAP(
    () => {
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: 'top 30%',
          end: '90% 120%',
          scrub: 1.5, // Slower response to scrolling for smoother animations
        },
      });

      tl.current.fromTo(
        '#title',
        { yPercent: -200, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          duration: 1.5, // Longer duration for slower animations
          ease: 'power3.out',
        }
      );

      tl.current.fromTo(
        '#first',
        { yPercent: -150, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          duration: 1.5, // Slower transition
          ease: 'power3.out',
        },
        '-=1'
      );

      tl.current.fromTo(
        '#second',
        { yPercent: 150, xPercent: -150, opacity: 0 },
        {
          yPercent: 0,
          xPercent: 0,
          opacity: 1,
          duration: 2, // Longer duration for a more drawn-out effect
          ease: 'power3.out',
        },
        '-=1'
      );

      tl.current.fromTo(
        '#fifth',
        { yPercent: 150, xPercent: -150, opacity: 0 },
        {
          yPercent: 0,
          xPercent: 0,
          opacity: 1,
          duration: 2,
          ease: 'power3.out',
        },
        '-=0.8'
      );

      tl.current.fromTo(
        '#third',
        { yPercent: 100, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          duration: 1.5,
          ease: 'power3.out',
        },
        '-=1.5'
      );

      tl.current.fromTo(
        '#fourth',
        { yPercent: 200, xPercent: 150, opacity: 0 },
        {
          yPercent: 0,
          xPercent: 0,
          opacity: 1,
          duration: 2, // Longer duration for a slower animation
          ease: 'power3.out',
        },
        '-=1.5'
      );
    },
    { scope: container }
  );

  return (
    <Grid
      className="slide"
      ref={container}
      w="100vw"
      h="100%"
      px={70}
      pt={40}
      gutter={30}
    >
      <Grid.Col span={8} id="title">
        <AnimatedText
          fw={500}
          maw={'90%'}
          textContent="M-AGI-C transforms your dropshipping business with a complete, automated, and optimized solution. Add perfectly crafted products to your store and let artificial intelligence accelerate your growth."
          textSize={38}
        />
      </Grid.Col>

      <Grid.Col span={4} id="first">
        <Image
          src={fourthImage}
          h="30vh"
          style={{
            objectFit: 'contain',
            backgroundPosition: 'center',
            border: '2px solid grey',
          }}
        />
      </Grid.Col>

      <Grid.Col span={3} id="second">
        <Image
          src={firstImage}
          h="55vh"
          style={{
            objectFit: 'cover',
            backgroundPosition: 'center',
            border: '2px solid grey',
          }}
        />
      </Grid.Col>

      <Grid.Col span={5} id="third">
        <Image
          src={thirdImage}
          h="36vh"
          style={{
            objectFit: 'contain',
            backgroundPosition: 'center',
            border: '2px solid grey',
          }}
        />
      </Grid.Col>
      <div id="fifth" className="absolute right-[18vw] bottom-[10vh] z-40">
        <Image src={fifthImage} h="70vh" style={{ objectFit: 'cover' }} />
      </div>
      <Grid.Col span={4} id="fourth">
        <Image
          src={secondImage}
          h="36vh"
          style={{
            objectFit: 'cover',
            backgroundPosition: 'center',
            border: '2px solid grey',
          }}
        />
      </Grid.Col>
    </Grid>
  );
});
