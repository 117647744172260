import { FC, memo } from "react";
import { Box, Drawer, Flex, Image, Text } from "@mantine/core";
import { ArtworkDataType } from "src/modules/products-screens/types";

interface ArtworkInfoProps {
    opened: boolean;
    close: () => void;
    artworkData: ArtworkDataType;
}

export const ArtworkInfo: FC<ArtworkInfoProps> = memo(
    ({ opened, close, artworkData }) => {
        return (
            <Drawer
                size={650}
                padding="0 30"
                opened={opened}
                onClose={close}
                returnFocus={false}
                overlayProps={{ backgroundOpacity: 0, blur: 0 }}
                styles={{
                    close: {
                        height: 40,
                        width: 40,
                    },
                    content: {
                        borderRight: "1px solid black",
                    },
                }}
            >
                <Flex direction="column" align={{ base: "center", sm: "flex-start" }}>
                    <Text fz={32} fw={600}>
                        Artwork #{artworkData.artwork_id}
                    </Text>

                    <Image w="60%" src={artworkData.image_url} alt="Generated Artwork" />
                </Flex>

                <Flex direction="column" justify="space-between" gap={20}>
                    <Box>
                        <Text fz={24} fw={600} mb={10}>
                            Prompt
                        </Text>

                        <Text>{artworkData.prompt_used}</Text>
                    </Box>

                    <Flex fw={600} justify="space-between" wrap="wrap" gap={20}>
                        <span>Persona ID: {artworkData.persona_id}</span>
                        <span>Created: {new Date(artworkData.created_at).toLocaleDateString()}</span>
                        <span>Model: {artworkData.model_used}</span>
                    </Flex>
                </Flex>
            </Drawer>
        );
    }
);
