import { FC } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import { usePersonas } from "src/selected-personas-provider";
import { Flex, Button } from "@mantine/core";
import { IconWand } from "@tabler/icons-react";
import { deletePersonas } from "src/services/automation.service";

interface PersonasControllerProps {
    isCreationProcessStarted: boolean;
    handleManualCreation: () => void;
    handleAutoCreation: () => void;
}

export const PersonasController: FC<PersonasControllerProps> = (
    { isCreationProcessStarted, handleManualCreation, handleAutoCreation }
) => {
    const { selectedPersonaIds, setSelectedPersonaIds } = usePersonas();

    const queryClient = useQueryClient();
    const { getAccessTokenSilently } = useAuth0();

    const handleDeleteSelected = async () => {
        const token = await getAccessTokenSilently();
        await deletePersonas(token, selectedPersonaIds);
        setSelectedPersonaIds([]);
        await queryClient.invalidateQueries({ queryKey: ["personas"] });
    };

    return <Flex justify="space-between" w="100%">
        <Button
            p={0}
            variant="transparent"
            onClick={handleDeleteSelected}>
            {selectedPersonaIds.length !== 0 && "Delete selected"}
        </Button>

        {!isCreationProcessStarted &&
            <Flex gap={30}>
                <Button p={0} variant="transparent" onClick={handleManualCreation}>Manual creation of persona</Button>
                <Button variant="outline" c="black" onClick={handleAutoCreation}>
                    <Flex gap={5} align="center">
                        <IconWand height={20} />
                        Auto create Persona
                    </Flex>
                </Button>
            </Flex>
        }
    </Flex>
};
