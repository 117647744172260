import { FC, memo, useRef } from "react";
import { Text } from "@mantine/core";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useOnScreen from "../../components/use-on-screen";

interface SlideNumberProps {
  slideNumber: number;
  side: "left" | "right";
  color?: "light" | "dark";
}

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const SlideNumber: FC<SlideNumberProps> = memo(
  ({ slideNumber, side, color = "dark" }) => {
    const container = useRef<HTMLParagraphElement | null>(null);
    const isInView = useOnScreen(container, true);

    useGSAP(
      () => {
        if (isInView) {
          gsap.fromTo(
            container.current,
            {
              yPercent: 150,
              opacity: 0,
            },
            {
              yPercent: 0,
              opacity: 1,
              duration: 1,
              ease: "power1.inOut",
            }
          );
        }
      },
      { scope: container, dependencies: [isInView] }
    );

    return (
      <Text
        ref={container}
        c={color === "light" ? "#fefcf0" : "#939598"}
        pos="absolute"
        fw={600}
        bottom={20}
        style={{ fontSize: 38, zIndex: 100, [side]: "40px" }}
      >
        {String(slideNumber).padStart(2, "0")}
      </Text>
    );
  }
);
