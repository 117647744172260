import { FC } from "react";

interface MenuCloseIconProps {
  opened: boolean;
}

export const MenuCloseIcon: FC<MenuCloseIconProps> = ({ opened }) => {
  return opened ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6l-12 12" />
      <path d="M6 6l12 12" />
    </svg>
  ) : (
    <svg
      width="33"
      height="22"
      viewBox="0 0 33 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="7" width="20" height="3" fill="black" />
      <rect x="7" y="18" width="20" height="3" fill="black" />
      <rect x="2" y="9" width="20" height="3" fill="black" />
    </svg>
  );
};
