import { FC, Fragment, memo, PropsWithChildren, useEffect, useState } from "react";
import { AnimatedCardWrapper } from "src/modules/products-screens/products-list/components/animated-card-wrapper";
import { ProductDataType } from "src/models/product";

interface ProductCardProps {
  productData: ProductDataType;
  animated?: boolean;
}

export const ProductCard: FC<ProductCardProps & PropsWithChildren> = memo(
  ({ productData, animated = true, children }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const img = new Image();
      img.src = productData.artwork_image_url;
      img.onload = () => setIsLoading(false);
    }, [productData.artwork_image_url]);

    const Wrapper = animated ? AnimatedCardWrapper : Fragment;

    return (
      <Wrapper>
        <div className="relative w-full pb-[100%] rounded-lg overflow-hidden">
          <div className="absolute inset-0">
            {isLoading && (
              <div className="h-full w-full bg-gray-300 animate-pulse" />
            )}
            <img
              src={productData.artwork_image_url || productData.product_images[0]}
              alt={productData.product_name}
              draggable={false}
              className="h-full w-full object-cover"
            />
          </div>
        </div>
        {children}
      </Wrapper>
    );
  }
);
