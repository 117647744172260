import { FC, memo, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { Button, Flex, Modal, Select } from "@mantine/core";
import { Supplier } from "src/models/supplier";
import { getUserSuppliers } from "src/services/automation.service";

interface TargetSupplierViewProps {
  selectedSupplier: Supplier | null;
  setSelectedSupplier: (selectedSupplier: Supplier | null) => void;
}

export const TargetSupplierView: FC<TargetSupplierViewProps> = memo(
  ({ selectedSupplier, setSelectedSupplier }) => {
    const { getAccessTokenSilently } = useAuth0();

    const suppliersQuery = useQuery({
      queryKey: ["suppliers"],
      queryFn: async () => {
        return getUserSuppliers(await getAccessTokenSilently());
      },
    });

    const transformToSupplier = (data: any): Supplier => {
      const supplierData = data[0];

      return {
        id: supplierData.id,
        name: supplierData.name,
        required_feilds: supplierData.required_feilds,
      };
    };

    const suppliers = useMemo(() => {
      if (suppliersQuery.isSuccess && suppliersQuery?.data?.data?.length) {
        return suppliersQuery?.data?.data?.map((supplier: any) =>
          Object.fromEntries(
            Object.entries(supplier).filter(
              ([key]) => !key.startsWith("__") && !key.startsWith("_")
            )
          )
        );
      }

      return [];
    }, [suppliersQuery?.data, suppliersQuery.isSuccess]);

    console.log("suppliers", suppliers);

    const handleSelectSupplier = async (supplierName: string) => {
      if (!supplierName) return setSelectedSupplier(null);

      const newlySelectedSupplier = transformToSupplier(
        suppliers.filter((supplier: any) => supplierName === supplier.name)
      );

      setSelectedSupplier(newlySelectedSupplier);
    };

    if (!suppliers) {
      return (
        <Modal centered opened withCloseButton={false} onClose={() => null}>
          <Flex direction="column" gap={10} align="center" ta="center" fz={20}>
            Please regiser a supplier before proceeding to create a store
            <Button component="a" href="/" fz={16}>
              Register a supplier
            </Button>
          </Flex>
        </Modal>
      );
    }

    return (
      <Flex
        direction="column"
        p={5}
        justify="center"
        align="center"
        w="100%"
        h="100%"
      >
        <Select
          p={20}
          w="60%"
          miw={250}
          label="Select a Supplier"
          placeholder="Select a Supplier"
          searchable
          value={selectedSupplier?.name || null}
          onChange={(value) => handleSelectSupplier(value || "")}
          data={suppliers.map((supplier: any) => supplier.name)}
        />
      </Flex>
    );
  }
);
