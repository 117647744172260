import { memo, useState } from "react";
import { Button, Stepper, Flex } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import { printProviders } from "src/services/automation.service";
import { Supplier } from "src/models/supplier";
import { Store } from "src/models/store";
import { TargetSupplierView } from "src/components/views/target-supplier-view";
import { GenerateBlueprintsView } from "src/components/views/generate-blueprints-view";
import { PageLayout } from "src/components/page-layout";

export const StorePage = memo(() => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(
    null
  );
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const [selectedBlueprintIds, setSelectedBlueprintIds] = useState<number[]>(
    []
  );

  const { getAccessTokenSilently } = useAuth0();

  const printSelectedProviders = async () => {
    const accessToken = await getAccessTokenSilently();

    printProviders({ blueprint_ids: selectedBlueprintIds }, accessToken);
  };

  const nextStep = () =>
    setActiveStep((prev: number) => {
      if (prev >= 2 || !selectedSupplier) {
        return prev;
      }

      if (prev === 1) {
        printSelectedProviders();
      }

      return prev + 1;
    });

  const prevStep = () =>
    setActiveStep((prev: number) => {
      if (prev <= 0) {
        return prev;
      }

      return prev - 1;
    });

  return (
    <PageLayout>
      <Flex
        h="100%"
        direction="column"
        justify="space-between"
        gap={20}
        px={35}
        py={10}
      >
        <Stepper active={activeStep} onStepClick={setActiveStep}>
          <Stepper.Step
            maw="25%"
            label="Select a Supplier"
            description="description"
          >
            <TargetSupplierView
              selectedSupplier={selectedSupplier}
              setSelectedSupplier={setSelectedSupplier}
            />
          </Stepper.Step>

          <Stepper.Step
            maw="25%"
            label="Generate Blueprints"
            description="description"
          >
            <GenerateBlueprintsView
              selectedSupplier={selectedSupplier}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
              selectedBlueprintIds={selectedBlueprintIds}
              setSelectedBlueprintIds={setSelectedBlueprintIds}
            />
          </Stepper.Step>

          <Stepper.Step
            maw="25%"
            label="Create & Sync Product Templates"
            description="description"
          ></Stepper.Step>
        </Stepper>

        <Flex justify="space-between" pb={20}>
          <Button
            variant="default"
            onClick={prevStep}
            disabled={activeStep === 0}
          >
            Previous
          </Button>
          {activeStep !== 2 && (
            <Button
              onClick={nextStep}
              disabled={activeStep === 0 && !selectedSupplier}
            >
              Next Step
            </Button>
          )}
        </Flex>
      </Flex>
    </PageLayout>
  );
});
