import { FC, useState } from "react";
import {
  Button,
  Grid,
  Modal,
  Select,
  TextInput,
  MultiSelect,
  NumberInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { saveWaitlistData } from "../../../services/automation.service";

interface WaitlistFormValues {
  name: string;
  email: string;
  phone?: string;
  businessWebsite?: string;
  businessModel: string;
  platforms: string[];
  businessGrowth: string;
  challenges: string;
  timeSpent: string;
  features: string[];
  importanceOfFeatures: Record<string, number>;
  integrations: string[];
  likelihoodOfSwitching: string;
  currentMethods: string[];
  satisfaction: string;
  trendAnalysisImprovements: string[];
  support: string;
  excitingFeatures: string[];
  interestInCaseStudies: string;
  additionalComments?: string;
  otherBusinessModel?: string;
  otherPlatform?: string;
  otherChallenge?: string;
  otherIntegration?: string;
}

interface WaitlistModalProps {
  opened: boolean;
  onClose: () => void;
}

export const WaitlistModal: FC<WaitlistModalProps> = ({ opened, onClose }) => {
  const [showOtherBusinessModel, setShowOtherBusinessModel] = useState(false);
  const [showOtherPlatform, setShowOtherPlatform] = useState(false);
  const [showOtherChallenge, setShowOtherChallenge] = useState(false);
  const [showOtherIntegration, setShowOtherIntegration] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const form = useForm<WaitlistFormValues>({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      businessWebsite: "",
      businessModel: "",
      platforms: [],
      businessGrowth: "",
      challenges: "",
      timeSpent: "",
      features: [],
      importanceOfFeatures: {
        automaticTrendAnalysis: 0,
        aiGeneratedDesigns: 0,
        inventoryOptimization: 0,
        ecommerceIntegration: 0,
        realTimeAnalytics: 0,
      },
      integrations: [],
      likelihoodOfSwitching: "",
      currentMethods: [],
      satisfaction: "",
      trendAnalysisImprovements: [],
      support: "",
      excitingFeatures: [],
      interestInCaseStudies: "",
      additionalComments: "",
      otherBusinessModel: "",
      otherPlatform: "",
      otherChallenge: "",
      otherIntegration: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      name: (value) => (value.trim().length > 0 ? null : "Name is required"),
    },
  });

  const handleSubmit = async (values: WaitlistFormValues) => {
    try {
      await saveWaitlistData(values);
      console.log("Waitlist data submitted successfully");
      setSuccessMessage("Thank you for joining the waitlist! We will get back to you soon.");
      setTimeout(() => {
        setSuccessMessage("");
        onClose();
      }, 3000);
    } catch (error) {
      console.error("Error submitting waitlist data:", error);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={<span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>Join the Magic AI Waitlist</span>}
      size="lg"
      styles={{ content: { width: '100%'} }}  
    >
      {successMessage ? (
        <div style={{ textAlign: 'center' }}>
          {successMessage}
        </div>
      ) : (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid gutter="md">
            <Grid.Col span={6}>
              <TextInput
                required
                label="Email"
                placeholder="your@email.com"
                {...form.getInputProps("email")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                required
                label="Full Name"
                placeholder="John Doe"
                {...form.getInputProps("name")}
              />
            </Grid.Col>
          </Grid>
          <TextInput
            label="Phone (optional)"
            placeholder="Your Phone Number"
            mt="md"
            {...form.getInputProps("phone")}
          />
          <TextInput
            label="Business Website or Social Media Link (optional)"
            placeholder="Your Business Website or Social Media Link"
            mt="md"
            {...form.getInputProps("businessWebsite")}
          />
          <Select
            required
            label="What best describes your business model?"
            placeholder="Select your business model"
            data={[
              { value: "POD", label: "Print-on-Demand (POD)" },
              { value: "ecommerce", label: "E-commerce/Dropshipping" },
              { value: "agency", label: "Creative Agency" },
              { value: "other", label: "Other" },
            ]}
            mt="md"
            {...form.getInputProps("businessModel")}
            onChange={(value) => {
              form.setFieldValue("businessModel", value ?? "");
              setShowOtherBusinessModel(value === "other");
            }}
          />
          {showOtherBusinessModel && (
            <TextInput
              label="Please specify your business model"
              placeholder="Your business model"
              mt="md"
              {...form.getInputProps("otherBusinessModel")}
            />
          )}
          <MultiSelect
            required
            label="Which platform(s) are you currently using to sell products?"
            placeholder="Select platforms"
            data={[
              { value: "shopify", label: "Shopify" },
              { value: "woocommerce", label: "WooCommerce" },
              { value: "amazon", label: "Amazon" },
              { value: "etsy", label: "Etsy" },
              { value: "other", label: "Other" },
            ]}
            mt="md"
            {...form.getInputProps("platforms")}
            onChange={(values) => {
              form.setFieldValue("platforms", values);
              setShowOtherPlatform(values.includes("other"));
            }}
          />
          {showOtherPlatform && (
            <TextInput
              label="Please specify the platform(s)"
              placeholder="Your platform(s)"
              mt="md"
              {...form.getInputProps("otherPlatform")}
            />
          )}
          <Select
            required
            label="How would you describe your business's level of growth?"
            placeholder="Select your business growth"
            data={[
              { value: "starting", label: "Just starting out" },
              { value: "early", label: "Early growth (some sales, expanding operations)" },
              { value: "established", label: "Established business (consistent sales and growth)" },
              { value: "high", label: "High growth (scaling rapidly, exploring automation)" },
            ]}
            mt="md"
            {...form.getInputProps("businessGrowth")}
          />
          <Select
            required
            label="What is your biggest challenge with your current POD/e-commerce setup?"
            placeholder="Select your challenge"
            data={[
              { value: "inventory", label: "Managing and optimizing inventory" },
              { value: "trends", label: "Keeping up with product trends" },
              { value: "automation", label: "Automating repetitive tasks" },
              { value: "quality", label: "Ensuring quality in design and production" },
              { value: "other", label: "Other" },
            ]}
            mt="md"
            {...form.getInputProps("challenges")}
            onChange={(value) => {
              form.setFieldValue("challenges", value ?? "");
              setShowOtherChallenge(value === "other");
            }}
          />
          {showOtherChallenge && (
            <TextInput
              label="Please specify your challenge"
              placeholder="Your challenge"
              mt="md"
              {...form.getInputProps("otherChallenge")}
            />
          )}
          <Select
            required
            label="On average, how much time do you spend each week on POD or e-commerce management?"
            placeholder="Select time spent"
            data={[
              { value: "less5", label: "Less than 5 hours" },
              { value: "5-10", label: "5-10 hours" },
              { value: "10-20", label: "10-20 hours" },
              { value: "more20", label: "More than 20 hours" },
            ]}
            mt="md"
            {...form.getInputProps("timeSpent")}
          />
          <MultiSelect
            required
            label="What features or solutions would make your POD management more effective?"
            placeholder="Select features"
            data={[
              { value: "marketTrends", label: "Predicting and adapting to market trends" },
              { value: "designCreation", label: "Automated design creation" },
              { value: "inventoryManagement", label: "Inventory management with optimization insights" },
              { value: "analytics", label: "Real-time analytics for decision-making" },
              { value: "fullAutomation", label: "Full automation of order fulfillment" },
            ]}
            mt="md"
            {...form.getInputProps("features")}
          />
          <Grid gutter="md" mt="md">
            <Grid.Col span={6}>
              <NumberInput
                required
                label="Automatic trend analysis (Rate 1-5, with 5 as essential)"
                min={1}
                max={5}
                {...form.getInputProps("importanceOfFeatures.automaticTrendAnalysis")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <NumberInput
                required
                label="AI-generated designs and templates (Rate 1-5, with 5 as essential)"
                min={1}
                max={5}
                {...form.getInputProps("importanceOfFeatures.aiGeneratedDesigns")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <NumberInput
                required
                label="Inventory and stock optimization (Rate 1-5, with 5 as essential)"
                min={1}
                max={5}
                {...form.getInputProps("importanceOfFeatures.inventoryOptimization")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <NumberInput
                required
                label="Seamless integration with e-commerce platforms (Rate 1-5, with 5 as essential)"
                min={1}
                max={5}
                {...form.getInputProps("importanceOfFeatures.ecommerceIntegration")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <NumberInput
                required
                label="Real-time analytics and performance tracking (Rate 1-5, with 5 as essential)"
                min={1}
                max={5}
                {...form.getInputProps("importanceOfFeatures.realTimeAnalytics")}
              />
            </Grid.Col>
          </Grid>
          <MultiSelect
            required
            label="What integrations would you find essential in an AI-driven POD solution?"
            placeholder="Select integrations"
            data={[
              { value: "ecommerceStore", label: "Direct integration with my e-commerce store (e.g., Shopify, WooCommerce)" },
              { value: "socialMedia", label: "Link to social media for trend analysis" },
              { value: "supplierIntegration", label: "Supplier integration for automated fulfillment" },
              { value: "other", label: "Other" },
            ]}
            mt="md"
            {...form.getInputProps("integrations")}
            onChange={(values) => {
              form.setFieldValue("integrations", values);
              setShowOtherIntegration(values.includes("other"));
            }}
          />
          {showOtherIntegration && (
            <TextInput
              label="Please specify the integration(s)"
              placeholder="Your integration(s)"
              mt="md"
              {...form.getInputProps("otherIntegration")}
            />
          )}
          <Select
            required
            label="How likely are you to consider switching to a fully automated AI-based POD platform?"
            placeholder="Select likelihood"
            data={[
              { value: "veryLikely", label: "Very Likely" },
              { value: "somewhatLikely", label: "Somewhat Likely" },
              { value: "neutral", label: "Neutral" },
              { value: "unlikely", label: "Unlikely" },
            ]}
            mt="md"
            {...form.getInputProps("likelihoodOfSwitching")}
          />
          <MultiSelect
            required
            label="What methods do you currently use to stay on top of POD trends?"
            placeholder="Select methods"
            data={[
              { value: "marketResearch", label: "Regular market research" },
              { value: "industryNews", label: "Following industry news and trend analysis tools" },
              { value: "designTools", label: "Using design tools with trending templates" },
              { value: "noMethod", label: "I don't have a specific method" },
            ]}
            mt="md"
            {...form.getInputProps("currentMethods")}
          />
          <Select
            required
            label="How satisfied are you with your current approach to trend analysis and design creation?"
            placeholder="Select satisfaction"
            data={[
              { value: "verySatisfied", label: "Very Satisfied" },
              { value: "somewhatSatisfied", label: "Somewhat Satisfied" },
              { value: "neutral", label: "Neutral" },
              { value: "dissatisfied", label: "Dissatisfied" },
              { value: "veryDissatisfied", label: "Very Dissatisfied" },
            ]}
            mt="md"
            {...form.getInputProps("satisfaction")}
          />
          <MultiSelect
            required
            label="What would make trend analysis more effective for your business?"
            placeholder="Select improvements"
            data={[
              { value: "realTimeInsights", label: "Real-time insights" },
              { value: "automatedSuggestions", label: "Automated suggestions based on market trends" },
              { value: "regularUpdates", label: "Regular updates on popular designs and themes" },
              { value: "visualizationTools", label: "Visualization tools to analyze market shifts" },
            ]}
            mt="md"
            {...form.getInputProps("trendAnalysisImprovements")}
          />
          <Select
            required
            label="What type of support would you value most in an early access program?"
            placeholder="Select support"
            data={[
              { value: "tutorials", label: "Exclusive tutorials and training on using the platform" },
              { value: "customerSupport", label: "24/7 customer support" },
              { value: "checkIns", label: "Regular check-ins with product specialists" },
              { value: "detailedGuides", label: "Detailed guides on optimizing my store with AI" },
            ]}
            mt="md"
            {...form.getInputProps("support")}
          />
          <MultiSelect
            required
            label="Which feature excites you most about MAGIC’s capabilities?"
            placeholder="Select features"
            data={[
              { value: "predictiveTrendAnalysis", label: "Predictive trend analysis to stay competitive" },
              { value: "designAutomation", label: "AI-driven design automation for fresh products" },
              { value: "inventoryOptimization", label: "Inventory and pricing optimization" },
              { value: "orderManagement", label: "Seamless, end-to-end order management" },
            ]}
            mt="md"
            {...form.getInputProps("excitingFeatures")}
          />
          <Select
            required
            label="Would you be interested in exclusive case studies or tips for leveraging AI in your e-commerce business?"
            placeholder="Select interest"
            data={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            mt="md"
            {...form.getInputProps("interestInCaseStudies")}
          />
          <TextInput
            label="Please share any specific features or insights you would love to see in MAGIC (optional)"
            placeholder="Your comments"
            mt="md"
            {...form.getInputProps("additionalComments")}
          />
          <Button type="submit" fullWidth mt="xl">
            Join Waitlist
          </Button>
        </form>
      )}
    </Modal>
  );
};
