import { FC, memo } from 'react';
import { TextInput, Text } from '@mantine/core';

interface FormInputProps {
  editMode: boolean;
  label: string;
  placeholder: string;
  value?: string | number;
  onChange?: (value: string) => void;
}

export const FormInput: FC<FormInputProps> = memo(
  ({ editMode, label, placeholder, onChange, value }) => {
    return editMode ? (
      <TextInput
        label={`${label}:`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        styles={{
          root: { display: 'flex', alignItems: 'center', gap: '10px' },
          label: { whiteSpace: 'nowrap', fontSize: '16px' },
        }}
      />
    ) : (
      <Text fz={16} fw={500} w="100%">
        {label}: <span style={{ color: 'grey' }}>{placeholder}</span>
      </Text>
    );
  }
);
