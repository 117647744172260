import { useStoreContext } from 'src/contexts';

const useHeaderTitle = (pathname: string) => {
  const { storeName } = useStoreContext();
  
  const titles = {
    "/store": "Store",
    "/store-creator": "My Stores",
    "/store-info": storeName || "Store Info",
    "/products-dashboard": "Products",
    "/personas-dashboard": "My Personas",
    "/my-personas": "My Personas",
    "/persona-creator": "Persona",
    "/products-creator": "My Products",
    "/products": "My Products",
    "/artworks": "My Artworks",
  } as const;

  return titles[pathname as keyof typeof titles] || '';
};

export { useHeaderTitle };