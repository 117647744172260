import { FC, memo, useId, useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Text, TextProps } from '@mantine/core';
import useOnScreen from './use-on-screen';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

interface AnimatedTextProps
  extends TextProps,
  React.ComponentProps<typeof Text> {
  textContent: string;
  textSize?: number;
  delay?: number;
  speed?: number; // New parameter to control speed
  fromColor?: string;
  toColor?: string;
}

export const AnimatedText: FC<AnimatedTextProps> = memo(
  ({
    textContent,
    textSize,
    delay = 0,
    speed = 1, // Default speed is 1 (normal speed)
    fromColor = '#333',
    toColor = '#333',
    ...props
  }) => {
    const textRef = useRef<HTMLParagraphElement | null>(null);
    const isInView = useOnScreen(textRef, true);

    const id = useId();

    useGSAP(
      () => {
        if (!isInView || !textContent) return;

        const words = textContent
          .split(' ')
          .map((word) => {
            // Use Array.from() instead of split('') to handle emojis correctly
            const letters = Array.from(word)
              .map(
                (letter) =>
                  `<span class="letter" style="display: inline-block; transform: translateY(100%)">${letter}</span>`
              )
              .join('');

            return `<div style="display: inline-block; margin: 0">${letters}</div>`;
          })
          .join(' ');

        if (textRef.current) {
          textRef.current.innerHTML = words;
        }

        const letterElems = textRef.current?.querySelectorAll('.letter');

        if (isInView && letterElems) {
          const totalLetters = letterElems.length;

          // Adjust the duration and stagger based on the speed prop (higher speed = faster animation)
          const duration = Math.max(0.2, (1.2 - totalLetters / 150) / speed); // Inverse relationship: higher speed = faster animation
          const stagger = Math.max(
            0.02,
            (0.1 - totalLetters / 150 / 5) / speed
          ); // Decrease stagger time with higher speed

          gsap.fromTo(
            letterElems,
            { opacity: 0, y: 50 },
            {
              opacity: 1,
              y: 0,
              ease: 'power4.out',
              duration: duration,
              delay: delay,
              stagger: stagger,
            }
          );

          gsap.fromTo(
            textRef.current,
            {
              color: fromColor,
            },
            {
              color: toColor,
              delay: delay + 2,
              duration: 2,
            }
          );
        }
      },
      { scope: textRef, dependencies: [isInView] }
    );

    return (
      <Text
        ref={textRef}
        id={id}
        lh={1.3}
        fz={textSize}
        c={fromColor}
        {...props} // Allows arbitrary props like `fw`
      >
        {textContent}
      </Text>
    );
  }
);
