import { memo, useRef } from 'react';
import { Box, Divider, Flex } from '@mantine/core';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { AnimatedText } from '../../../components';
import { SlideNumber } from '../slide-number';
import SecondImage from '../../../../../assets/Landing/FourthPage-2.avif';
import FirstImage from '../../../../../assets/Landing/FourthPage-1.avif';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const FourthSlide = memo(() => {
  const container = useRef<HTMLDivElement | null>(null);
  const textBlock = useRef<HTMLDivElement | null>(null);

  useGSAP(
    () => {
      // gsap.fromTo(
      //   textBlock.current,
      //   {
      //     opacity: 1,
      //   },
      //   {
      //     opacity: 0,
      //     scrollTrigger: {
      //       trigger: container.current,
      //       start: "center center",
      //       scrub: true,
      //     },
      //   }
      // );
    },
    { scope: container }
  );

  return (
    <Box
      ref={container}
      className="slide"
      w="100vw"
      h="100%"
      display="flex"
      pos="relative"
    >
      <Box
        flex="0 0 60%"
        h="100%" // Ensure it takes full height
        style={{
          backgroundImage: `url(${FirstImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
        }}
      ></Box>

      <Divider color="black" orientation="vertical" />

      <Flex
        ref={textBlock}
        flex="0 0 40%"
        p={100}
        align="center"
        justify="center"
        direction="column"
        gap={60}
      >
        <AnimatedText
          textSize={38}
          fw={500}
          speed={1000}
          textContent="Ready to Revolutionize Your POD Business?"
        />

        <AnimatedText
          delay={0}
          fw={500}
          speed={3000}
          textSize={16}
          textContent="Join our exclusive beta program and be among the first to discover how AI can transform your print-on-demand business. Easily integrate with major tech partners and become part of a forward-thinking community of POD entrepreneurs."
        />

        <Box
          w="100%"
          h="35vh"
          flex="0 0 60%"
          style={{
            backgroundImage: `url(${SecondImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',

            backgroundPosition: 'right',
          }}
        ></Box>
      </Flex>

      <SlideNumber slideNumber={4} side="left" color="light" />
    </Box>
  );
});
