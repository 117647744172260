import { FC, memo, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { Checkbox, Flex, InputLabel, RangeSlider, Select, Text } from "@mantine/core";
import { getCollectionsList, getUniqueCategoriesList, getUniquePersonasList, getUniqueThemesList, getUserStores } from "src/services/automation.service";
import { ProductFilterType } from "src/modules/filter/types";
import { UniquePersonaType } from "src/models/persona";
import { StoreBasicDataType } from "src/modules/store-creator/types/store-data";
import { FilterDate } from "src/modules/filter/components/filter-date";
import { FilterDateType } from "src/modules/products-screens/types";

interface ProductsFilterProps {
    newFilters: ProductFilterType;
    setNewFilters: (newFilters: ProductFilterType) => void;
}

export const ProductsFilter: FC<ProductsFilterProps> = memo(({ newFilters, setNewFilters }) => {
    const { getAccessTokenSilently } = useAuth0();

    const { data: collectionsList } = useQuery({
        queryKey: ['collections'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return (await getCollectionsList(token)).data || [];
        },
    });

    const { data: themesList } = useQuery({
        queryKey: ['themes'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return (await getUniqueThemesList(token)).data || [];
        },
    });

    const { data: categoriesList } = useQuery({
        queryKey: ['categories'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return (await getUniqueCategoriesList(token)).data || [];
        },
    });

    const { data: personasList } = useQuery({
        queryKey: ['personasList'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const personas: UniquePersonaType[] = (await getUniquePersonasList(token)).data || [];

            return personas;
        },
    });

    const personasSelectorData = personasList?.map((persona) => ({
        value: String(persona.id),
        label: persona.name,
    }));

    const storesQuery = useQuery({
        queryKey: ['stores'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return getUserStores(token);
        },
    });

    const storesList: StoreBasicDataType[] = useMemo(() => {
        return storesQuery.isSuccess ? storesQuery?.data?.data : [];
    }, [storesQuery]);

    const storeSelectorData = storesList?.map((store) => ({
        value: String(store.store_id),
        label: store.store_name,
    }));

    const selectedDate = {
        fromDate: newFilters.start_date ? new Date(newFilters.start_date) : null,
        toDate: newFilters.end_date ? new Date(newFilters.end_date) : null
    };

    const handleCollectionSelect = (value: string | null) => {
        setNewFilters({
            ...newFilters,
            collection_name: value || ""
        })
    };

    const handleThemeSelect = (value: string | null) => {
        setNewFilters({
            ...newFilters,
            theme: value || ""
        })
    };

    const handleCategorySelect = (value: string | null) => {
        setNewFilters({
            ...newFilters,
            category: value || ""
        })
    };

    const handlePersonaSelect = ((value: string | null) => {
        const persona = personasList?.find(({ id }) => id === Number(value));

        setNewFilters({
            ...newFilters,
            persona: persona
        });
    });

    const handlePriceChange = (value: number[]) => {
        const [min, max] = value;

        setNewFilters({
            ...newFilters,
            min_price: min,
            max_price: max
        });
    };

    const handleSyncStatusChange = (value: boolean) => {
        setNewFilters({
            ...newFilters,
            is_synced: value
        });
    };

    const handleStoreSelect = (value: string | null) => {
        const store = storesList?.find(({ store_id }) => store_id === Number(value));

        setNewFilters({
            ...newFilters,
            store: store
        });
    };

    const handleDateChange = (dateValue: FilterDateType) => {
        setNewFilters({
            ...newFilters,
            start_date: dateValue.fromDate?.toISOString().split('T')[0],
            end_date: dateValue.toDate?.toISOString().split('T')[0]
        });
    };

    return (
        <Flex direction="column" gap={15}>
            <Select
                label="Collection"
                flex="1 0 auto"
                placeholder="Collection"
                defaultValue={newFilters.collection_name || ""}
                data={collectionsList}
                onChange={handleCollectionSelect}
            />

            <Select
                label="Theme"
                flex="1 0 auto"
                placeholder="Theme"
                defaultValue={newFilters.theme || ""}
                data={themesList}
                onChange={handleThemeSelect}
            />

            <Select
                label="Category"
                flex="1 0 auto"
                placeholder="Category"
                defaultValue={newFilters.category || ""}
                data={categoriesList}
                onChange={handleCategorySelect}
            />

            <Select
                label="Persona"
                placeholder="Persona"
                defaultValue={newFilters.persona?.name || ""}
                onChange={handlePersonaSelect}
                data={personasSelectorData}
            />

            <InputLabel>Price:</InputLabel>
            <RangeSlider
                minRange={1}
                min={0}
                max={1000}
                defaultValue={[newFilters.min_price || 0, newFilters.max_price || 1000]}
                color="black"
                size="sm"
                onChange={handlePriceChange} />

            <Flex justify="space-between" gap={10}>
                <Text fz={14}>min: {newFilters.min_price || 0}</Text>
                <Text fz={14}>max: {newFilters.max_price || 1000}</Text>
            </Flex>

            <Checkbox
                checked={newFilters.is_synced}
                label="Sync status:"
                labelPosition="left"
                color="black"
                onChange={(e) => handleSyncStatusChange(e.currentTarget.checked)}
            />

            <Select
                label="Store"
                flex="1 0 auto"
                placeholder="Store"
                defaultValue={String(newFilters.store?.store_id) || ""}
                data={storeSelectorData}
                onChange={handleStoreSelect}
            />

            <FilterDate
                selectedDate={selectedDate}
                setSelectedDate={handleDateChange}
            />
        </Flex>
    );
});
