import { useLayoutEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from 'src/components/page-loader';

export const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  const loginUser = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
      authorizationParams: {
        prompt: 'login',
      },
    });
  };

  useLayoutEffect(() => {
    loginUser();
  }, []);

  return <PageLoader />;
};
