import { FC, useEffect, useState } from "react";
import { Checkbox, Switch } from "@mantine/core";
import { CreatedProductData, SavedProductData } from "src/models/product";
import { useProducts } from "src/selected-products-provider";

interface CreatedProductCardProps {
    productData: CreatedProductData;
}

export const CreatedProductCard: FC<CreatedProductCardProps> = ({ productData }) => {
    const { storeId, productsToPublish, savedProducts, setSavedProducts, setProductsToPublish } = useProducts();

    const [isLoading, setIsLoading] = useState(true);

    const handleProductSave = (productData: CreatedProductData) => {
        if (!storeId) return;

        const savedData: SavedProductData = {
            blueprint_id: productData.blueprint_id,
            final_image: productData.final_image,
            image: productData.image,
            persona_id: productData.persona_id,
            price: productData.price,
            prompt: productData.prompt,
            store_id: storeId
        };

        const isSaved = savedProducts.some((savedProduct) => savedProduct.persona_id === savedData.persona_id);

        if (isSaved) {
            setSavedProducts(savedProducts.filter(
                (savedProduct) => savedProduct.persona_id !== savedData.persona_id
            ));
        } else {
            setSavedProducts([...savedProducts, savedData]);
        }
    };

    useEffect(() => {
        const img = new Image();
        img.src = productData.final_image;
        img.onload = () => setIsLoading(false);
    }, [productData.final_image]);

    const {
        blueprint_id,
        count,
        final_image,
        image,
        persona_id,
        price,
        prompt
    } = productData;

    const handleSelect = () => {
        const isSelected = productsToPublish.includes(persona_id);

        if (isSelected) {
            setProductsToPublish(productsToPublish.filter((id) => id !== persona_id));
        } else {
            setProductsToPublish([...productsToPublish, persona_id]);
        }
    };

    return (
        <Checkbox.Card
            w="max-content"
            h="100%"
            checked={productsToPublish.includes(persona_id)}
            value={String(persona_id)}
            pos="relative"
            radius="md"
            onClick={handleSelect}
            draggable={false}
            style={{
                position: "relative"
            }}
        >
            {isLoading && (
                <div className="absolute z-20 h-full w-full bg-gray-50">
                    <div className="h-full w-full bg-gray-300 animate-pulse" />
                </div>
            )}

            <div className="flex flex-col h-full w-80 rounded-lg border border-solid border-gray-200 shadow-lg overflow-hidden">
                <div className="relative aspect-square">
                    <img
                        src={final_image || image}
                        alt={prompt}
                        draggable={false}
                        className="w-full h-full object-cover"
                    />
                </div>

                <div className="flex flex-col flex-grow gap-3 p-4 text-sm min-h-0">
                    <div className="flex justify-between items-end">
                        <span className="font-medium">
                            ${price.toFixed(2)}
                        </span>

                        <div className="self-start">
                            <span className="text-xs">Saved</span>
                            <Switch
                                color="black"
                                size="xs"
                                checked={savedProducts.some((savedProduct) => savedProduct.persona_id === productData.persona_id)}
                                styles={{
                                    track: { width: "40px", height: "12px", cursor: "pointer" },
                                    thumb: { height: "8px", width: "8px" }
                                }}
                                onChange={() => handleProductSave(productData)}
                            />
                        </div>
                    </div>

                    <p className="h-full overflow-hidden flex-grow m-0 text-justify">
                        {prompt}
                    </p>

                    <div className="flex justify-between gap-2 text-gray-600 border-t border-gray-100">
                        <div>
                            <span>Blueprint: </span>
                            <span className="font-medium">{blueprint_id}</span>
                        </div>
                        <div>
                            <span>Persona: </span>
                            <span className="font-medium">{persona_id}</span>
                        </div>
                        <div>
                            <span>Count: </span>
                            <span className="font-medium">{count}</span>
                        </div>
                    </div>
                </div>
            </div>

            <Checkbox.Indicator
                size="md"
                pos="absolute"
                top={10}
                right={10}
                styles={{
                    icon: { cursor: "pointer" },
                    indicator: { cursor: "pointer" },
                }}
            />
        </Checkbox.Card>
    );
};
