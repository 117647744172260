import { Flex } from '@mantine/core';
import { FC, memo } from 'react';
import { AnimatedText } from 'src/modules/landing-screens';

interface WelcomeMessageProps {
  userName: string
  message: string
}

export const WelcomeMessage: FC<WelcomeMessageProps> = memo(({ userName, message }) => {
  return (
    <Flex flex="1 0 auto" direction="column" justify="center" gap={20} className="w-full max-w-[1600px]">
      <Flex direction="column" gap={20}>
        <AnimatedText 
          textSize={36} 
          delay={0} 
          textContent={`Hi ${userName}!`} 
        />
        <AnimatedText
          textSize={36}
          delay={0.5}
          toColor="#999"
          textContent={message}
        />
      </Flex>
    </Flex>
  );
});

export default WelcomeMessage;