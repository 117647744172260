import { FC, memo } from 'react';
import { Textarea, Text } from '@mantine/core';

interface FormTextareaProps {
  editMode: boolean;
  text: string;
  onChange: (value: string) => void;
}

export const FormTextarea: FC<FormTextareaProps> = memo(
  ({ editMode, text, onChange }) => {
    return editMode ? (
      <Textarea
        value={text}
        onChange={(e) => onChange(e.target.value)}
        h="100%"
        autosize
        styles={{
          input: {
            fontSize: '16px',
            color: '#939598',
            padding: '10px',
          },
        }}
      />
    ) : (
      <Text
        c="#939598"
        ta="justify"
        h="100%"
        fz={16}
        p={10}
        style={{
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: 'grey',
        }}
      >
        {text}
      </Text>
    );
  }
);
