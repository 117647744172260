import React, { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction, useEffect } from "react";
import { CreatedProductData, ProductDataType, SavedProductData } from "src/models/product";

interface ProductContextProps {
  storeId: number | null;
  setStoreId: Dispatch<SetStateAction<number | null>>;
  selectedProductIds: string[];
  setSelectedProductIds: Dispatch<SetStateAction<string[]>>;
  productsData: ProductDataType[];
  setProductsData: Dispatch<SetStateAction<ProductDataType[]>>;
  selectedProductData: ProductDataType | null;
  setSelectedProductData: Dispatch<SetStateAction<ProductDataType | null>>;
  createdProducts: CreatedProductData[];
  setCreatedProducts: Dispatch<SetStateAction<CreatedProductData[]>>;
  savedProducts: SavedProductData[];
  setSavedProducts: Dispatch<SetStateAction<SavedProductData[]>>;
  productsToPublish: number[];
  setProductsToPublish: Dispatch<SetStateAction<number[]>>;
}

const ProductContext = createContext<ProductContextProps | null>(null);

interface ProductProviderProps {
  children: ReactNode;
}

export const useProducts = (): ProductContextProps => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProducts must be used within a ProductProvider");
  }
  return context;
};

export const ProductProvider: React.FC<ProductProviderProps> = ({ children }) => {
  const [storeId, setStoreId] = useState<number | null>(null);
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [productsData, setProductsData] = useState<ProductDataType[]>([]);
  const [selectedProductData, setSelectedProductData] = useState<ProductDataType | null>(null);
  const [createdProducts, setCreatedProducts] = useState<CreatedProductData[]>([]);
  const [savedProducts, setSavedProducts] = useState<SavedProductData[]>([]);
  const [productsToPublish, setProductsToPublish] = useState<number[]>([]);

  useEffect(() => {
    if (!storeId || createdProducts.length === 0) return;

    const newSavedProducts: SavedProductData[] = createdProducts.map(product => {
      return {
        blueprint_id: product.blueprint_id,
        final_image: product.final_image,
        image: product.image,
        persona_id: product.persona_id,
        price: product.price,
        prompt: product.prompt,
        store_id: storeId
      };
    });

    setSavedProducts(newSavedProducts);
  }, [createdProducts]);

  return (
    <ProductContext.Provider
      value={{
        storeId,
        setStoreId,
        selectedProductIds,
        setSelectedProductIds,
        productsData,
        setProductsData,
        selectedProductData,
        setSelectedProductData,
        createdProducts,
        setCreatedProducts,
        savedProducts,
        setSavedProducts,
        productsToPublish,
        setProductsToPublish
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
