import { Box } from '@mantine/core';
import { createContext, useRef, useState } from 'react';
import { Header } from 'src/modules/header';
import { FirstScreen, SecondScreen } from 'src/modules/landing-screens';
import { ThirdScreen } from 'src/modules/landing-screens/third-screen';

export const LandingPage = () => {
  const animationContext = createContext(false);
  const [isText, setisText] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  return (
    <animationContext.Provider value={isText}>
      <Box bg="#fefcf0" className="content-layout">
        <Header title="MAGIC" />
        <FirstScreen buttonRef={buttonRef} />
        <SecondScreen />
        <ThirdScreen buttonRef={buttonRef} />
      </Box>
    </animationContext.Provider>
  );
};
