import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePersonas } from 'src/selected-personas-provider';
import { Switch } from '@mantine/core';
import { CreatedPersonaType } from 'src/models/persona';

interface CreatedPersonaInfoProps {
    persona: CreatedPersonaType;
    index: number;
    setActiveCardIndex: (index: number | null) => void;
    handlePersonasSaving: (savedNames: string[]) => Promise<void>;
}

export const CreatedPersonaInfo: FC<CreatedPersonaInfoProps> = ({
    persona,
    index,
    setActiveCardIndex,
    handlePersonasSaving
}) => {
    const { persona_name, persona_descriptions, age, persona_image, target_audience } = persona;

    const [isLoading, setIsLoading] = useState(true);
    const { savedPersonaNames, setSavedPersonaNames } = usePersonas();

    const navigate = useNavigate();

    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const isSaved = e.target.checked;

        setSavedPersonaNames((prev: string[]) => {
            if (isSaved) {
                return [...prev, persona_name];
            } else {
                return prev.filter((name) => name !== persona.persona_name);
            }
        });
    };

    const handleProductCreation = async () => {
        navigate("/products");

        await handlePersonasSaving(savedPersonaNames);
    };

    useEffect(() => {
        const img = new Image();
        img.src = persona_image;
        img.onload = () => setIsLoading(false);
    }, [persona_image]);

    return (
        <div
            className="h-full rounded border border-gray-200 cursor-pointer overflow-hidden w-[300px] bg-[#fefcf0] relative z-10"
            onMouseEnter={() => setActiveCardIndex(index)}
            onMouseLeave={() => setActiveCardIndex(null)}
        >
            <div className="h-full p-6 flex flex-col">
                <div className="w-full h-48 mb-4 overflow-hidden rounded-lg flex-shrink-0">
                    {isLoading
                        ? <div className="h-full w-full bg-gray-300 animate-pulse" />
                        : <img
                            src={persona_image}
                            alt={`${persona_name}'s profile`}
                            className="w-full h-full object-cover"
                        />
                    }
                </div>

                <div className="flex justify-between items-end gap-3 flex-shrink-0">
                    <h2 className="m-0 text-lg font-semibold">{persona_name}</h2>
                    <div className="self-start">
                        <span className='text-xs'>{savedPersonaNames.includes(persona_name) ? "Saved" : "Save"}</span>
                        <Switch
                            color='black'
                            size="xs"
                            styles={{
                                track: { width: "40px", height: "12px", cursor: "pointer" },
                                thumb: { height: "8px", width: "8px" }
                            }}
                            checked={savedPersonaNames.includes(persona_name)}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="text-xs text-gray-600 mt-2 flex-shrink-0">
                    <div className="flex items-center space-x-2">
                        <span>Age: {age}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <span>Target Audience: {target_audience}</span>
                    </div>
                </div>

                <div className="mt-4 flex-grow overflow-hidden transition-all duration-300 ease-in-out">
                    <h3 className="font-medium text-sm">Description:</h3>
                    <p className="text-xs text-gray-600">{persona_descriptions}</p>
                </div>

                <button onClick={handleProductCreation} className="w-full h-10 mt-4 border bg-transparent hover:bg-black hover:text-white border-black rounded-md text-xs font-medium cursor-pointer flex-shrink-0 transition-colors duration-300">
                    Create a product from {persona_name}
                </button>
            </div>
        </div>
    );
};
