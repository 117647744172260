import { FC, memo, useMemo, useState } from "react";
import { Button, Flex, Loader } from "@mantine/core";
import { ProductFormLine } from "src/modules/product-creation-form/components/product-form-line";
import { useQuery } from "@tanstack/react-query";
import { createProducts, readStore } from "src/services/automation.service";
import { useAuth0 } from "@auth0/auth0-react";
import { SelectedBlueprintType } from "src/models/blueprint";
import { CheckboxCardElem } from "src/modules/products-slider";
import { PersonaCard } from "src/modules/personas-screens/components";
import { BlueprintCard } from "src/modules/store-info/components/blueprint-card";
import { SQSProductMessage } from "src/models/product";
import { BlueprintInfoElem } from "src/modules/product-creation-form/components/blueprint-info-elem";
import { StoreSelector } from "src/modules/store-creator/components/store-selector";
import { AnimatedText } from "src/modules/landing-screens";
import { StoreFullDataType } from "src/modules/store-creator/types/store-data";
import { useProducts } from "src/selected-products-provider";

interface BlueprintInputData {
  price: number;
  quantity: number;
}

interface ProductFormErrors {
  personas: boolean;
  blueprints: boolean;
}
interface ProductCreatingFormProps {
  setCreationMessages: (messages: SQSProductMessage[]) => void;
  setIsCreateFormOpened: (isOpened: boolean) => void;
}

export const ProductCreatingForm: FC<ProductCreatingFormProps> = memo(
  ({ setCreationMessages, setIsCreateFormOpened }) => {
    const { storeId, setStoreId } = useProducts();
    const [personaIds, setPersonaIds] = useState<number[]>([]);
    const [selectedBlueprints, setSelectedBlueprints] = useState<SelectedBlueprintType[]>([]);
    const [blueprintInputs, setBlueprintInputs] = useState<Record<string, BlueprintInputData>>({});
    const [errors, setErrors] = useState<ProductFormErrors>({
      personas: true,
      blueprints: true
    });

    const [isLoading, setIsLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const getErrorMessage = (errors: ProductFormErrors): string => {
      if (!errors.personas && !errors.blueprints) return "";

      const missingItems = [];
      if (errors.personas) missingItems.push('persona');
      if (errors.blueprints) missingItems.push('blueprint');

      return `Please select at least one ${missingItems.join(' and ')}`;
    };

    const validateForm = () => {
      const newErrors = {
        personas: personaIds.length === 0,
        blueprints: selectedBlueprints.length === 0
      };

      setErrors(newErrors);
      return !newErrors.personas && !newErrors.blueprints;
    };

    const handlePersonaSelect = (selectedId: number) => {
      const isSelected = personaIds.includes(selectedId);
      const updatedPersonas = isSelected
        ? personaIds.filter(id => id !== selectedId)
        : [...personaIds, selectedId];

      setPersonaIds(updatedPersonas);
      setErrors(prev => ({
        ...prev,
        personas: updatedPersonas.length === 0
      }));
    };

    const handleProductCreation = async () => {
      if (!validateForm()) return;

      try {
        setIsLoading(true);

        const token = await getAccessTokenSilently();

        const { artwork_task_id, message } = (await createProducts(token, selectedBlueprints, personaIds)).data;

        if (artwork_task_id && message) {
          setCreationMessages([{
            artwork_task_id: artwork_task_id,
            message: message,
            result: "",
            title: "Creation ..."
          }]);
        }
      } catch (error) {
        console.error("Error creating products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const { data: storeQuery, isLoading: isStoreDataLoading, isSuccess: isStoreSuccess } = useQuery({
      queryKey: ["store-data", storeId],
      queryFn: async () => {
        return storeId ? readStore(await getAccessTokenSilently(), storeId) : null;
      },
    });

    const storeData: StoreFullDataType = useMemo(() => {
      return isStoreSuccess ? storeQuery?.data : [];
    }, [storeQuery?.data, isStoreSuccess]);

    const handleBlueprintSelect = (selectedId: string) => {
      const numericId = Number(selectedId);
      const selectedBlueprint = storeData.blueprints?.blueprint_data?.find(({ id }) => id === numericId);
      const isSelected = selectedBlueprints?.some(({ id }) => id === numericId);

      setSelectedBlueprints(prev => {
        let newBlueprints = [...prev];

        if (isSelected) {
          newBlueprints = newBlueprints.filter(({ id }) => id !== numericId);
        } else if (selectedBlueprint) {
          const blueprintToAdd = {
            id: numericId,
            price: blueprintInputs[selectedId]?.price ?? 0,
            quantity: blueprintInputs[selectedId]?.quantity ?? 1
          };
          newBlueprints.push(blueprintToAdd);
        }

        setErrors(prev => ({
          ...prev,
          blueprints: newBlueprints.length === 0
        }));

        return newBlueprints;
      });
    };

    const handlePriceChange = (blueprintId: string, value: string) => {
      const numericPrice = Number(value);
      if (isNaN(numericPrice)) return;

      setBlueprintInputs(prev => ({
        ...prev,
        [blueprintId]: {
          ...prev[blueprintId],
          price: numericPrice
        }
      }));

      setSelectedBlueprints(prev =>
        prev.map(blueprint =>
          blueprint.id === Number(blueprintId)
            ? { ...blueprint, price: numericPrice }
            : blueprint
        )
      );
    };

    const handleQuantityChange = (blueprintId: string, value: string) => {
      const numericQuantity = Number(value);
      if (isNaN(numericQuantity)) return;

      setBlueprintInputs(prev => ({
        ...prev,
        [blueprintId]: {
          ...prev[blueprintId],
          quantity: numericQuantity
        }
      }));

      setSelectedBlueprints(prev =>
        prev?.map(blueprint =>
          blueprint.id === Number(blueprintId)
            ? { ...blueprint, quantity: numericQuantity }
            : blueprint
        )
      );
    };

    return (
      <div className="flex flex-col gap-5 h-full">
        <div className="flex justify-between">
          <StoreSelector setStoreId={setStoreId} className="w-40 bg-transparent rounded-sm border-gray-400" />
          <button
            className="block md:hidden text-sm sm:text-base bg-inherit outline-none border-none hover:text-gray-700 cursor-pointer"
            onClick={() => setIsCreateFormOpened(false)}>
            Back to products
          </button>
        </div>

        {isLoading || isStoreDataLoading &&
          <Flex justify="center" align="center" h="100%" w="100%">
            <Loader />
          </Flex>
        }

        {storeId && !isLoading && !isStoreDataLoading &&
          <>
            <div className="flex flex-col gap-3 flex-grow">
              <ProductFormLine title="Persona" className="flex-[40%]">
                {storeData.persona?.persona_data?.length === 0
                  ? <div className="w-full h-full flex justify-center items-center text-center">
                    <AnimatedText textContent="It looks like you haven't created any persona yet. Start by creating your first persona to see it displayed here." />
                  </div>
                  : storeData.persona?.persona_data?.map((personaData) => (
                    <CheckboxCardElem
                      key={personaData.persona_id}
                      id={String(personaData.persona_id)}
                      selectedIds={personaIds.map(String)}
                      name={personaData.persona_name}
                      checkboxSize="sm"
                      handleSelect={() => personaData.persona_id && handlePersonaSelect(personaData.persona_id)}
                    >
                      <PersonaCard
                        key={personaData.persona_id}
                        personaData={personaData}
                      />
                    </CheckboxCardElem>
                  ))}
              </ProductFormLine>

              <ProductFormLine title="Blueprint" className="flex-[60%]">
                {storeData.blueprints?.blueprint_data?.length === 0
                  ? <div className="w-full h-full flex justify-center items-center text-center">
                    <AnimatedText textContent="It looks like you haven't created any blueprints yet. Start by creating your first blueprint to see it displayed here." />
                  </div>
                  : storeData.blueprints?.blueprint_data?.map((blueprintData) => (
                    <div key={blueprintData.id} className="flex flex-col h-full items-center">
                      <div className="flex-[50%]">
                        <CheckboxCardElem
                          id={String(blueprintData.id)}
                          selectedIds={selectedBlueprints.map(({ id }) => String(id))}
                          checkboxSize="sm"
                          handleSelect={handleBlueprintSelect}
                        >
                          <BlueprintCard
                            blueprintData={blueprintData}
                          />
                        </CheckboxCardElem>
                      </div>
                      <BlueprintInfoElem
                        title={blueprintData.title}
                        changePrice={(value) => handlePriceChange(String(blueprintData.id), value)}
                        changeQuantity={(value) => handleQuantityChange(String(blueprintData.id), value)}
                      />
                    </div>
                  ))
                }
              </ProductFormLine>
            </div>

            <div className="flex flex-col gap-1">
              <Button
                onClick={handleProductCreation}
                variant="outline"
                w="max-content"
                c="black"
                mx="auto"
                h={30}
                disabled={isLoading || isStoreDataLoading || selectedBlueprints?.length === 0 || personaIds?.length === 0}
              >
                {isLoading ? "Creating..." : "Create Product"}
              </Button>

              {(errors.personas || errors.blueprints) && (
                <span className="text-center text-xs text-gray-500">
                  {getErrorMessage(errors)}
                </span>
              )}
            </div>
          </>}
      </div>
    );
  }
);
