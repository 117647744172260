import { memo, useRef } from "react";
import { BackgroundImage, Box, Flex, Image } from "@mantine/core";
import { AnimatedText } from "../../components";
import { motion } from "framer-motion";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

import _ScrollTrigger from "gsap/ScrollTrigger";
interface BenefitsBlockProps {
  heading: string;
  description: string;
  image: string;
}
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(_ScrollTrigger);

export const BenefitsBlock = memo<BenefitsBlockProps>(
  ({ heading, description, image }) => {
    const container = useRef<HTMLDivElement | null>(null);

    const circle = useRef<HTMLDivElement | null>(null);
    useGSAP(
      () => {
        gsap.from(".circle", { x: 360 }); // <-- automatically reverted
        // gsap code here...
        gsap.to(".circle", { x: 0 }); // <-- automatically reverted
      },
      { scope: container }
    ); // <-- scope for selector text (optional)
    const tl = useRef<GSAPTimeline | null>(null);

    return (
      <Flex
        ref={container}
        h="80vh"
        flex="0 0 39.9%"
        direction="column"
        justify="space-between"
      >
        <motion.div
          initial={{ opacity: 0, y: -100 }} // Initial state (hidden and moved down)
          whileInView={{ opacity: 1, y: 0 }} // When the div enters the view (fade in and move up)
          transition={{ duration: 0.7 }} // Smooth transition
          viewport={{ once: true, amount: 0.2 }} // Trigger when 30% of the element is in view
        >
          <Image
            h="35vh"
            style={{ objectFit: "cover" }}
            fit="fill"
            src={image}
          ></Image>
        </motion.div>

        <div className="px-16 flex flex-col justify-between gap-16">
          <Box px={30}>
            <AnimatedText
              speed={1000}
              fw={500}
              textSize={38}
              textContent={heading}
            />
          </Box>

          <Box px={30}>
            <AnimatedText
              speed={4000}
              fw={500}
              delay={0.4}
              textSize={18}
              textContent={description}
            />
          </Box>
        </div>
      </Flex>
    );
  }
);
