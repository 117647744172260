import React, { ReactNode } from 'react';

type TextFormatterProps = {
  text: string;
};

/**
 * TextFormatter component that formats a paragraph input by applying filters.
 *
 * @param {string} text - The input paragraph
 * @returns {JSX.Element} The formatted output
 */
const TextFormatter: React.FC<TextFormatterProps> = ({ text }) => {
  // Define filters and how to render each match.
  const filters: {
    [key: string]: (match: string, ...args: string[]) => ReactNode;
  } = {
    link: (_, linkText, url) => (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        {linkText}
      </a>
    ),
    bold: (_, boldText) => <b className="font-bold">{boldText}</b>,
    italic: (_, italicText) => <i className="italic">{italicText}</i>,
  };

  // Regular expressions for each filter.
  const regex: {
    [key: string]: RegExp;
  } = {
    link: /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g, // Match [linkText](url)
    bold: /\*\*(.*?)\*\*/g, // Match **bold**
    italic: /\*(.*?)\*/g, // Match *italic*
  };

  /**
   * Apply filters to input text and return an array of ReactNode elements.
   */
  const applyFilters = (inputText: string): ReactNode[] => {
    let segments: ReactNode[] = [inputText];

    // Apply each filter one by one.
    Object.keys(filters).forEach((filter) => {
      const regexPattern = regex[filter];
      segments = segments.flatMap((segment) => {
        if (typeof segment === 'string') {
          const parts: ReactNode[] = [];
          let lastIndex = 0;

          segment.replace(regexPattern, (match, ...args) => {
            const matchIndex = segment.indexOf(match, lastIndex);
            if (matchIndex > lastIndex) {
              parts.push(segment.slice(lastIndex, matchIndex));
            }

            // Call the filter function with matched arguments.
            parts.push(filters[filter](match, ...args));
            lastIndex = matchIndex + match.length;
            return match; // Return match for replace callback requirement.
          });

          // Append remaining text if any.
          if (lastIndex < segment.length) {
            parts.push(segment.slice(lastIndex));
          }

          return parts;
        }
        return segment; // Return non-string ReactNode segments as-is.
      });
    });

    return segments;
  };

  // Generate the formatted text as ReactNodes.
  const formattedText = applyFilters(text);

  // Render the formatted content within a <p> tag.
  return <p className="text-lg leading-relaxed">{formattedText}</p>;
};

export default TextFormatter;
