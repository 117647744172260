import { FC, useState } from "react";
import { PageLayout } from "src/components/page-layout";
import { CreatedPersonaInfo } from "./created-persona-info";
import { AnimatedText } from "src/modules/landing-screens";
import { usePersonas } from "src/selected-personas-provider";

interface CreatedPersonasProps {
    handlePersonasSaving: (savedNames: string[]) => Promise<void>;
}

export const CreatedPersonas: FC<CreatedPersonasProps> = ({ handlePersonasSaving }) => {
    const { createdPersonas, savedPersonaNames } = usePersonas();

    const [activeCardIndex, setActiveCardIndex] = useState<number | null>(null);

    const cardsWrapperWidth = 60;
    const cardWidth = cardsWrapperWidth / createdPersonas.length;

    const formattedNames = new Intl.ListFormat('en', {
        style: 'long',
        type: 'conjunction'
    }).format(createdPersonas.map(({ persona_name }) => persona_name));

    const text = `I have created ${formattedNames} for you`;

    const handleMyPersonasClick = async () => {
        await handlePersonasSaving(savedPersonaNames);
    };

    return (
        <PageLayout>
            <div className="flex h-full">
                <div className={`flex flex-1 h-full`}
                    style={{ width: createdPersonas.length > 3 ? `${cardsWrapperWidth}vw` : "" }}>
                    {createdPersonas.map((persona, index) => (
                        <div
                            key={persona.persona_name + index}
                            className="flex-shrink-0 shadow-sm transition-all duration-500 linear overflow-hidden"
                            style={{
                                width: index === activeCardIndex || createdPersonas.length < 3 ? '300px' : `${cardWidth}vw`,
                                borderRight: "1px solid black"
                            }}
                        >
                            <CreatedPersonaInfo
                                persona={persona}
                                index={index}
                                setActiveCardIndex={setActiveCardIndex}
                                handlePersonasSaving={handlePersonasSaving}
                            />
                        </div>
                    ))}
                </div>

                <div className="w-[35vw] flex-grow flex flex-col justify-between items-center p-10">
                    <div className="flex-grow flex items-center">
                        <AnimatedText textContent={text} textSize={24} />
                    </div>

                    <button onClick={handleMyPersonasClick} className="w-max px-4 py-2 border bg-transparent hover:bg-black hover:text-white border-black rounded-md text-xs font-medium cursor-pointer flex-shrink-0 transition-colors duration-300">
                        My personas
                    </button>
                </div>
            </div>
        </PageLayout>
    );
};
