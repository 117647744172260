import { FC } from "react";
import { Image } from "@mantine/core";
import logoImg from "src/assets/icons/logo-black.png";

interface StepElemProps {
    stepTitle: string;
    subTitle: string;
    isCompleted: boolean;
    setIsCreatedItemsOpened?: (opened: boolean) => void;
}

export const StepElem: FC<StepElemProps> = ({ stepTitle, subTitle, isCompleted, setIsCreatedItemsOpened }) => {
    const viewCreatedPersonas = () => {
        setIsCreatedItemsOpened && setIsCreatedItemsOpened(true);
    };

    return (
        <div
            draggable={false}
            className="h-full border aspect-square border-black border-solid rounded-xl text-gray-400 flex flex-col items-center justify-center p-4 gap-2 text-center"
        >
            <Image src={logoImg} alt="logo" w={80} />

            <span className="text-md leading-none">{stepTitle}</span>

            {isCompleted
                ? <button
                    className="bg-transparent hover:bg-gray-50 rounded-md py-1 px-2 text-xs cursor-pointer"
                    onClick={viewCreatedPersonas}>
                    Review and Publish
                </button>
                : <span className="text-xs">{subTitle}</span>
            }
        </div>
    );
};
