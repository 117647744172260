import {
  FC,
  forwardRef,
  memo,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Button, Flex, Group, Image, Space, Text } from '@mantine/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import logoImg from '../../../../assets/icons/logo-black.png';
import { AnimatedText } from '../../components/animated-text';
import { AnimatedButton } from './animated-button';
import { AnimatedScroll } from './animated-scroll';
import { WaitlistModal } from '../../../modal-manager/components/waitlist-modal';
import { PageLayout } from 'src/components/page-layout';
import { IconCamera } from '@tabler/icons-react';
import { useAnimate, useScroll, useVelocity } from 'framer-motion';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

interface FirstScreenProps {
  buttonRef: MutableRefObject<HTMLButtonElement | null>;
}

export const FirstScreen: FC<FirstScreenProps> = memo(
  forwardRef(({ buttonRef }) => {
    const [modalOpened, setModalOpened] = useState(false);
    const [scrollDownRef, animateScrollDown] = useAnimate();
    const { scrollYProgress } = useScroll();
    const xVelocity = useVelocity(scrollYProgress);
    useEffect(() => {
      return xVelocity.on('change', (latestVelocity) => {
        if (latestVelocity > 0) {
          animateScrollDown(
            scrollDownRef.current,
            { opacity: 0 },
            { duration: 0.1, ease: 'easeInOut' }
          );
        }

        if (latestVelocity < 0) {
          animateScrollDown(
            scrollDownRef.current,
            { opacity: 1 },
            { duration: 0.1, ease: 'easeInOut' }
          );
        }
      });
    }, []);

    const container = useRef<HTMLDivElement | null>(null);
    const tl = useRef<GSAPTimeline | null>(null);

    useGSAP(
      () => {
        tl.current = gsap.timeline();

        tl.current.fromTo(
          '#centralLogo',
          { opacity: 1, width: 200 },
          {
            opacity: 0,
            width: 100,
            duration: 2,
          }
        );
        tl.current.fromTo(
          '#main-title',
          { yPercent: 100, opacity: 0 },
          {
            yPercent: 0,
            opacity: 1,
            duration: 1,
            ease: 'power4.out',
          }
        );
        tl.current.fromTo(
          '#main-description',
          { yPercent: 100, opacity: 0 },
          {
            yPercent: 0,
            opacity: 1,

            ease: 'power4.out',
          }
        );
        tl.current.fromTo(
          '#centralLogoWrapper',
          { height: '100vh' },
          {
            height: 0,
            duration: 4,
          }
        );

        gsap.to('#textWrapper', {
          y: '-50vh',
          scrollTrigger: {
            trigger: container.current,
            scrub: true,
            start: 'top top',
          },
        });
      },
      { scope: container }
    );

    return (
      <PageLayout>
        <Box h="100%" ref={container} pos="relative" style={{ zIndex: 1 }}>
          <Flex
            pos="fixed"
            top={0}
            left={0}
            w="100vw"
            h="100vh"
            id="centralLogoWrapper"
            justify="center"
            align="center"
          >
            <Image id="centralLogo" alt="logo" src={logoImg} w={100} />
          </Flex>

          <Flex
            h="100%"
            direction="column"
            align="center"
            justify="flex-end"
            gap={120}
            pb={50}
          >
            <Box
              w={'85%'}
              pos="absolute"
              top="40%"
              ta="center"
              id="textWrapper"
            >
              <div className="overflow-hidden">
                <div id="main-title">
                  <div className="font-medium text-center leading-[1] text-[3rem] sm:text-[2.5rem] md:text-[2.75rem] lg:text-[3rem] xl:text-[3.5rem] 2xl:text-[2.9rem]">
                    Revolutionize Your Print-on-Demand Business with AI
                  </div>
                </div>
              </div>

              {/* <AnimatedText
                fw={600}
                delay={5}
                textContent="Revolutionize Your Print-on-Demand Business with Al"
                textSize={46}
              /> */}
              <div className="overflow-hidden mt-4 mx-[8%]">
                <div id="main-description">
                  <div className=" text-center text-[#999] font-[300] leading-[1.3] text-[0.9rem] sm:text-[0.5rem] md:text-[0.75rem] lg:text-[1rem] xl:text-[1.5rem] 2xl:text-[1.95rem]">
                    Automated solutions that empower growth, streamline
                    operations, and keep you ahead of the competition.
                  </div>
                </div>
              </div>
              {/* <div className="mt-10">
                <AnimatedText
                  delay={8}
                  fw={400}
                  fromColor="#999"
                  textContent="Automated solutions that empower growth, streamline operations, and keep you ahead of the competition."
                  textSize={25}
                />
              </div> */}
            </Box>
            <AnimatedButton
              buttonRef={buttonRef}
              container={container}
              title="Get Early Access"
              onClick={() => setModalOpened(true)}
            />
          </Flex>
          <AnimatedScroll ref={scrollDownRef} container={container} />

          <WaitlistModal
            opened={modalOpened}
            onClose={() => setModalOpened(false)}
          />
        </Box>
      </PageLayout>
    );
  })
);
