import { FC } from "react";
import { Button, Flex } from "@mantine/core";

interface ArtworksControllerProps {
    isArtworksSelected: boolean;
    handleDeleteSelected: () => void;
}

export const ArtworksController: FC<ArtworksControllerProps> = ({ isArtworksSelected, handleDeleteSelected }) => {
    return (
        <Flex justify="space-between" w="100%">
            <Button p={0} variant="transparent" onClick={handleDeleteSelected}>
                {isArtworksSelected && "Delete selected"}
            </Button>
        </Flex>
    );
};
