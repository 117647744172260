import { memo } from "react";
import { Button, Flex, Loader } from "@mantine/core";
import { StoreCreatorForm } from "src/modules/store-creator/components/store-creator-form";
import { useStoreContext } from "src/contexts";

export const StoreCreatorSecondStep = memo(() => {
  const { isStoreReading } = useStoreContext();

  if (isStoreReading) {
    return (
      <Flex justify="center" align="center" h="100%">
        <Loader />
      </Flex>
    );
  }

  return (
    <>
      <Button color="grey" variant="outline" style={{ alignSelf: "flex-end" }}>
        Let`s talk!
      </Button>

      <StoreCreatorForm />
    </>
  );
});
