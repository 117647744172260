import { FC, memo, PropsWithChildren, useState, useEffect, Fragment } from "react";
import { AnimatedCardWrapper } from "src/modules/products-screens/products-list/components/animated-card-wrapper";
import { PersonaDataType } from "src/models/persona";

interface PersonaCardProps {
    personaData: PersonaDataType;
    animated?: boolean;
}

export const PersonaCard: FC<PersonaCardProps & PropsWithChildren> = memo(
    ({ personaData, animated = true, children }) => {
        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            const img = new Image();
            img.src = personaData.image_url;
            img.onload = () => setIsLoading(false);
        }, [personaData.image_url]);

        const Wrapper = animated ? AnimatedCardWrapper : Fragment;

        return (
            <Wrapper>
                <div className="relative w-full pb-[100%] rounded-lg overflow-hidden">
                    <div className="absolute inset-0">
                        {isLoading && (
                            <div className="h-full w-full bg-gray-300 animate-pulse" />
                        )}
                        <img
                            src={personaData.image_url}
                            alt={personaData.persona_name}
                            className={`h-full w-full object-cover transition-opacity duration-300 ${isLoading ? 'opacity-0' : 'opacity-100'
                                }`}
                        />
                    </div>
                </div>
                {children}
            </Wrapper>
        );
    }
);
