import { FC, forwardRef, memo, MutableRefObject, useRef } from 'react';
import { Button } from '@mantine/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import { AnimatedText } from '../../components/animated-text';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

interface AnimatedButtonProps {
  container: MutableRefObject<HTMLDivElement | null>;
  title: string;
  buttonRef: MutableRefObject<HTMLButtonElement | null>;
  onClick: () => void;
}

export const AnimatedButton: FC<AnimatedButtonProps> = memo(
  forwardRef(({ container, title, buttonRef, onClick }) => {
    const tl = useRef<GSAPTimeline | null>(null);

    useGSAP(
      () => {
        tl.current = gsap.timeline({
          delay: 2,
        });

        tl.current.fromTo(
          buttonRef.current,
          {
            opacity: 0,
            width: 0,
            height: 0,
            clipPath: 'polygon(0% 100%, 0% 100%, 0% 100%, 0% 100%)',
          },
          {
            opacity: 1,
            width: '290px',
            height: '50px',
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
            ease: 'power1.out',
            smoothOrigin: true,
            duration: 1.5,
          }
        );

        gsap.from(buttonRef.current, {
          y: () => "-25vh",
          scrollTrigger: {
            trigger: container.current,
            end: 'center bottom',
            scrub: true,
          },
        });
      },
      { scope: container }
    );

    return (
      <Button
        radius="md"
        ref={buttonRef}
        onClick={onClick}
        pos="fixed"
        bottom={0}
        variant="outline"
        color="black"
        size="sm"
        lh={1}
        bg="#fefcf0"
        className="w-72 md:w-96"
        styles={{
          root: {
            zIndex: 5,
            outline: 'none',
            border: '2px solid black',
            fontWeight: 'bold',
            transform: 'translateY(-4.6rem)',
          },
        }}
      >
        <AnimatedText delay={2} textContent={title} textSize={20} />
      </Button>
    );
  })
);
