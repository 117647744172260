import React, { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction, useEffect } from "react";
import { CreatedPersonaType, PersonaDataType } from "./models/persona";

interface PersonaContextProps {
    personasData: PersonaDataType[];
    setPersonasData: Dispatch<SetStateAction<PersonaDataType[]>>;
    selectedPersonaIds: string[];
    setSelectedPersonaIds: Dispatch<SetStateAction<string[]>>;
    selectedPersonaData: PersonaDataType | null;
    setSelectedPersonaData: Dispatch<SetStateAction<PersonaDataType | null>>;
    createdPersonas: CreatedPersonaType[];
    setCreatedPersonas: Dispatch<SetStateAction<CreatedPersonaType[]>>;
    savedPersonaNames: string[];
    setSavedPersonaNames: Dispatch<SetStateAction<string[]>>;
}

const PersonaContext = createContext<PersonaContextProps | null>(null);

interface PersonaProviderProps {
    children: ReactNode;
}

export const usePersonas = (): PersonaContextProps => {
    const context = useContext(PersonaContext);
    if (!context) {
        throw new Error("usePersonas must be used within a PersonaProvider");
    }
    return context;
};

export const PersonaProvider: React.FC<PersonaProviderProps> = ({ children }) => {
    const [personasData, setPersonasData] = useState<PersonaDataType[]>([]);
    const [selectedPersonaIds, setSelectedPersonaIds] = useState<string[]>([]);
    const [selectedPersonaData, setSelectedPersonaData] = useState<PersonaDataType | null>(null);
    const [createdPersonas, setCreatedPersonas] = useState<CreatedPersonaType[]>([]);
    const [savedPersonaNames, setSavedPersonaNames] = useState<string[]>([]);

    useEffect(() => {
        const names = createdPersonas.map(({ persona_name }) => persona_name);
        setSavedPersonaNames(names);
    }, [createdPersonas]);

    return (
        <PersonaContext.Provider value={{
            personasData,
            setPersonasData,
            selectedPersonaIds,
            setSelectedPersonaIds,
            selectedPersonaData,
            setSelectedPersonaData,
            createdPersonas,
            setCreatedPersonas,
            savedPersonaNames,
            setSavedPersonaNames
        }}>
            {children}
        </PersonaContext.Provider>
    );
};
