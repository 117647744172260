import { FC, memo, useEffect, useMemo } from 'react';
import { Button, Select } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { getUserStores } from 'src/services/automation.service';
import { StoreBasicDataType } from 'src/modules/store-creator/types/store-data';
import { useNavigate } from 'react-router-dom';

interface StoreSelectorProps {
  setStoreId: (storeId: number) => void;
  className?: string;
  setEditMode?: (editMode: boolean) => void;
}

export const StoreSelector: FC<StoreSelectorProps> = memo(
  ({ setStoreId, className, setEditMode }) => {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const storesQuery = useQuery({
      queryKey: ['stores'],
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        return getUserStores(token);
      },
    });

    const stores: StoreBasicDataType[] = useMemo(() => {
      return storesQuery.isSuccess ? storesQuery?.data?.data : [];
    }, [storesQuery.data, storesQuery.isSuccess]);

    const selectorData = stores?.map((store) => ({
      value: String(store.store_id),
      label: store.store_name,
    }));

    useEffect(() => {
      if (stores?.length > 0 && !storesQuery.isLoading) {
        setStoreId(stores[0]?.store_id);
      }
    }, [storesQuery.isLoading, stores]);

    const createStore = () => {
      navigate("/onboarding");
    };

    if (stores.length === 0) {
      return (
        <Button
          onClick={createStore}
          variant="outline"
          size="xs"
          c="dark"
          className='w-40'
        >
          Add Store
        </Button>
      );
    }

    return className ? (
      <select
        className={className}
        onChange={(e) => {
          setStoreId(Number(e.target.value));
          setEditMode && setEditMode(false);
        }}
      >
        {selectorData.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
    ) : (
      <Select
        flex="1 0 auto"
        placeholder="Store"
        defaultValue={String(stores[0]?.store_id)}
        data={selectorData}
        onChange={(value) => setStoreId(Number(value))}
      />
    );
  }
);
