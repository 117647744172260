import { memo, useEffect, useMemo, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useWebsocketClient } from "src/modules/websocket-client";
import { Box, Flex, Loader } from "@mantine/core";
import { PageLayout } from "src/components/page-layout";
import { HorizontalCardsGrid } from "src/components/grids/horizontal-cards-grid";
import { Filter } from "src/modules/filter";
import { WelcomeMessage } from 'src/modules/products-screens/products-chat/components/welcome-message';
import { ProductsFilterPills } from "src/modules/filter/pills";
import { ProductsController, ProductInfo } from "src/modules/products-screens/products-list";
import { StepElem } from "src/modules/personas-screens/components";
import { getProducts } from "src/services/automation.service";
import { ProductDataType, SQSProductMessage } from "src/models/product";
import { CreatedProducts, ProductCreatingForm } from "src/modules/product-creation-form";
import { useProducts } from "src/selected-products-provider";
import { CreationProcess } from "src/modules/creation-process";
import { ProductElements } from "src/modules/products-screens/components/product-elements";

export const ProductsPage = memo(() => {
  const {
    productsData,
    setProductsData,
    selectedProductData,
    createdProducts,
    setCreatedProducts,
    setSavedProducts,
  } = useProducts();

  const { messages: websoketMessages } = useWebsocketClient();
  const [creationMessages, setCreationMessages] = useState<SQSProductMessage[]>([]);

  const [productInfoOpened, { open: openProductInfo, close: closeProductInfo }] = useDisclosure();
  const [filterOpened, { open: openFilter, close: closeFilter }] = useDisclosure(false);
  const [isCreateFormOpened, setIsCreateFormOpened] = useState(false);
  const [isCreatedProductsOpened, setIsCreatedProductsOpened] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();

  const creationStepsQuantity = 3;

  const { data: productsQuery, isSuccess, isLoading } = useQuery({
    queryKey: ["products"],
    queryFn: async () => {
      return getProducts(await getAccessTokenSilently());
    },
  });

  const products = useMemo(() => {
    return isSuccess ? productsQuery?.data : [];
  }, [productsQuery?.data, isSuccess]);

  useEffect(() => {
    setProductsData(products);
  }, [products]);

  useEffect(() => {
    const taskId = creationMessages[0]?.artwork_task_id;

    const newMessage: SQSProductMessage | null = websoketMessages.at(-1)?.artwork_task_id == taskId ? websoketMessages.at(-1) : null;

    if (!newMessage) return;

    const isAlreadySaved = newMessage.message === creationMessages.at(-1)?.message;

    !isAlreadySaved && setCreationMessages([...creationMessages, newMessage]);
    newMessage?.final_result && setCreatedProducts([newMessage.final_result]);
  }, [websoketMessages.length]);

  const handleManualCreation = () => {
    setIsCreateFormOpened(!isCreateFormOpened);
  };

  const resetState = () => {
    setIsCreatedProductsOpened(false);
    setIsCreateFormOpened(false);
    setCreatedProducts([]);
    setCreationMessages([]);
    setSavedProducts([]);
  };

  if (isLoading) {
    return (
      <PageLayout>
        <Flex justify="center" align="center" h="100%">
          <Loader />
        </Flex>
      </PageLayout>
    );
  };

  if (isCreatedProductsOpened) return <CreatedProducts resetState={resetState} />

  return (
    <PageLayout>
      {selectedProductData && <ProductInfo opened={productInfoOpened} close={closeProductInfo} />}

      <div className="flex h-full">
        <Flex
          direction="column"
          w={`${isCreateFormOpened ? "60%" : "100%"}`}
          display={{ base: `${isCreateFormOpened ? "none" : ""}`, sm: "flex" }}
          style={{ borderRight: "1px solid black" }}
          className="p-5 md:px-8"
        >
          <div className="flex flex-row gap-5 justify-between items-center w-full">
            <Filter<ProductDataType>
              opened={filterOpened}
              open={openFilter}
              close={closeFilter}
              type="products"
              setFilteredData={setProductsData} />

            <div className="flex flex-wrap gap-2">
              <ProductsFilterPills />
            </div>
          </div>

          <Box flex="1 0 auto" className={`flex-[65%] ${productsData?.length === 0 ? 'flex items-center justify-center' : ""}`}>
            {!isLoading && (
              <HorizontalCardsGrid
                footer={<ProductsController handleManualCreation={handleManualCreation} />}
              >
                {creationMessages.length > 0 && (
                  <StepElem
                    stepTitle={creationMessages.at(-1)?.title || ""}
                    subTitle="Creation of Products"
                    isCompleted={!!createdProducts.length}
                    setIsCreatedItemsOpened={setIsCreatedProductsOpened}
                  />
                )}

                {productsData?.length === 0
                  ? <div className="h-full flex items-center">
                    <WelcomeMessage
                      userName={user?.nickname || 'User'}
                      message="It looks like you haven't created any products yet. Start by creating your first product to see it displayed here."
                    />
                  </div>
                  : <ProductElements openProductInfo={openProductInfo} />
                }
              </HorizontalCardsGrid>
            )}
          </Box>
        </Flex>

        <Flex
          display={isCreateFormOpened ? "flex" : "none"}
          w={{ base: "100%", sm: `${isCreateFormOpened ? "40%" : "0%"}` }}
          className="p-5 md:px-8 overflow-hidden transition-all duration-300 ease-linear"
        >
          <Flex direction="column" w="100%" justify="space-between">
            {creationMessages.length > 0
              ? <CreationProcess
                header={createdProducts.length > 0 &&
                  <button
                    className="ml-auto inline-block md:hidden text-sm sm:text-base bg-inherit outline-none border-none hover:text-gray-700 cursor-pointer"
                    onClick={() => setIsCreatedProductsOpened(true)}>
                    View created products
                  </button>
                }
                creationMessages={creationMessages}
                stepsQuantity={creationStepsQuantity}
              />
              : <ProductCreatingForm setCreationMessages={setCreationMessages} setIsCreateFormOpened={setIsCreateFormOpened} />
            }
          </Flex>
        </Flex>
      </div>
    </PageLayout>
  );
});
