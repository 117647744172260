import { FC, memo, useMemo } from "react";
import { Flex, RangeSlider, Text, Select } from "@mantine/core";
import { FilterDate } from "src/modules/filter/components/filter-date";
import { PersonaFiltersType } from "src/modules/filter/types";
import { FilterDateType } from "src/modules/products-screens/types/filter-values";
import { useQuery } from "@tanstack/react-query";
import { getTargetAudienceList, getUniqueCountriesList, getUserStores } from "src/services/automation.service";
import { useAuth0 } from "@auth0/auth0-react";
import { StoreBasicDataType } from "src/modules/store-creator/types/store-data";

interface PersonasFilterProps {
    newFilters: PersonaFiltersType;
    setNewFilters: (newFilters: PersonaFiltersType) => void;
}

export const PersonasFilter: FC<PersonasFilterProps> = memo(({ newFilters, setNewFilters }) => {
    const { getAccessTokenSilently } = useAuth0();

    const { data: countriesList } = useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return (await getUniqueCountriesList(token)).data || [];
        },
    });

    const { data: targetAudienceList } = useQuery({
        queryKey: ['target_audience'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return (await getTargetAudienceList(token)).data || [];
        },
    });

    const selectedDate = {
        fromDate: newFilters.start_date ? new Date(newFilters.start_date) : null,
        toDate: newFilters.end_date ? new Date(newFilters.end_date) : null
    };

    const storesQuery = useQuery({
        queryKey: ['stores'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return getUserStores(token);
        },
    });

    const storesList: StoreBasicDataType[] = useMemo(() => {
        return storesQuery.isSuccess ? storesQuery?.data?.data : [];
    }, [storesQuery]);

    const selectorData = storesList?.map((store) => ({
        value: String(store.store_id),
        label: store.store_name,
    }));

    const handleAgeChange = (value: number[]) => {
        const [min, max] = value;

        setNewFilters({
            ...newFilters,
            min_age: min,
            max_age: max
        });
    };

    const handleCountrySelect = (value: string | null) => {
        setNewFilters({
            ...newFilters,
            country: value || ""
        });
    };

    const handleTargetAudienceSelect = (value: string | null) => {
        setNewFilters({
            ...newFilters,
            target_audience: value || ""
        })
    };

    const handleStoreSelect = (value: string | null) => {
        const store = storesList?.find(({ store_id }) => store_id === Number(value));

        setNewFilters({
            ...newFilters,
            store: store
        });
    };

    const handleDateChange = (dateValue: FilterDateType) => {
        setNewFilters({
            ...newFilters,
            start_date: dateValue.fromDate?.toISOString().split('T')[0],
            end_date: dateValue.toDate?.toISOString().split('T')[0]
        });
    };

    return (
        <Flex direction="column" gap={15}>
            <RangeSlider
                minRange={1}
                min={0}
                max={100}
                defaultValue={[newFilters.min_age || 0, newFilters.max_age || 100]}
                color="black"
                size="sm"
                onChange={handleAgeChange} />

            <Flex justify="space-between" gap={10}>
                <Text>min: {newFilters.min_age || 0}</Text>
                <Text>max: {newFilters.max_age || 100}</Text>
            </Flex>

            <Select
                label="Country"
                flex="1 0 auto"
                placeholder="Country"
                defaultValue={newFilters.country || ""}
                data={countriesList}
                onChange={handleCountrySelect}
            />

            <Select
                label="Target Audience"
                flex="1 0 auto"
                placeholder="Target Audience"
                defaultValue={newFilters.target_audience || ""}
                data={targetAudienceList}
                onChange={handleTargetAudienceSelect}
            />

            <Select
                label="Store"
                flex="1 0 auto"
                placeholder="Store"
                defaultValue={String(newFilters.store?.store_id) || ""}
                data={selectorData}
                onChange={handleStoreSelect}
            />

            <FilterDate
                selectedDate={selectedDate}
                setSelectedDate={handleDateChange}
            />
        </Flex>
    );
});
