import { Stepper } from "@mantine/core";

import React, { useState } from "react";
import { PageLayout } from "../components/page-layout";
import { useSteppers } from "src/stepper-provider";
import { PersonaView } from "../components/views/persona-view";
import { TrendView } from "../components/views/trends-view";
import { AnalysisView } from "../components/views/trends-analysis-view";

const Countries = {
  AR: "Argentina",
  AU: "Australia",
  AT: "Austria",
  BE: "Belgium",
  BR: "Brazil",
  CA: "Canada",
  CL: "Chile",
  CO: "Colombia",
  CZ: "Czechia",
  DK: "Denmark",
  EG: "Egypt",
  FI: "Finland",
  FR: "France",
  DE: "Germany",
  GR: "Greece",
  HK: "Hong Kong",
  HU: "Hungary",
  IN: "India",
  ID: "Indonesia",
  IE: "Ireland",
  IL: "Israel",
  IT: "Italy",
  JP: "Japan",
  KE: "Kenya",
  MY: "Malaysia",
  MX: "Mexico",
  NL: "Netherlands",
  NZ: "New Zealand",
  NG: "Nigeria",
  NO: "Norway",
  PE: "Peru",
  PH: "Philippines",
  PL: "Poland",
  PT: "Portugal",
  RO: "Romania",
  RU: "Russia",
  SA: "Saudi Arabia",
  SG: "Singapore",
  ZA: "South Africa",
  KR: "South Korea",
  ES: "Spain",
  SE: "Sweden",
  CH: "Switzerland",
  TW: "Taiwan",
  TH: "Thailand",
  TR: "Türkiye",
  UA: "Ukraine",
  GB: "United Kingdom",
  US: "United States",
  VN: "Vietnam",
};

export const PersonaCreatorPage: React.FC = () => {
  const [selectedTrends, setSelectedTrends] = useState<any[]>([]);
  const [selectedAudienceGroups, setSelectedAudienceGroups] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("DE");
  const { selectedSteppers, setSelectedSteppers } = useSteppers();
  
  const nextStep = () =>
    setSelectedSteppers((current) => (current < 3 ? current + 1 : current));
  
  const resetStepper = () => {
    setSelectedSteppers(0);
    setSelectedTrends([]);
  };


  return (
    <PageLayout>
      <Stepper
        active={selectedSteppers}
        allowNextStepsSelect={false}
        onStepClick={(step) => {
          setSelectedSteppers(step);
          setSelectedTrends([]);
        }}
      >
        <Stepper.Step
          maw="25%"
          label="Collect Trending Data"
          description="Select a hashtags to collect trending data"
        >
          <TrendView
            countries={Countries}
            selectedTrends={selectedTrends}
            setSelectedTrends={setSelectedTrends}
            nextStep={nextStep}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
        </Stepper.Step>
        <Stepper.Step
          maw="25%"
          label="Analyze Trends"
          description="Analyze the collected trends"
        >
          <AnalysisView
            trends={selectedTrends}
            resetStepper={resetStepper}
            selectedAudienceGroups={selectedAudienceGroups}
            setSelectedAudienceGroups={setSelectedAudienceGroups}
            nextStep={nextStep}
            selectedCountry={selectedCountry}
          />
        </Stepper.Step>
        <Stepper.Step
          maw="25%"
          label="Make Personas"
          description="With the collected trends, generate personas"
        >
          {/* <PersonaView
            trends={selectedTrends}
            resetStepper={resetStepper}
            selectedAudienceGroups={selectedAudienceGroups}
            selectedCountry={selectedCountry}
          /> */}
        </Stepper.Step>
      </Stepper>
    </PageLayout>
  );
};
