import { memo, useEffect, useMemo, useState } from "react";
import { Flex, Loader } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { Filter } from "src/modules/filter";
import { PageLayout } from "src/components/page-layout";
import { deleteArtwork, getArtworks } from "src/services/automation.service";
import { ArtworkCard } from "src/modules/products-screens/products-list/components/artwork-card";
import { CheckboxCardElem } from "src/modules/products-slider";
import { ArtworksFilterPills } from "src/modules/filter/pills";
import { ArtworkDataType } from "src/modules/products-screens/types/artwork-data";
import { ArtworkInfo } from "src/modules/artworks-screens/components/artwork-info";
import { WelcomeMessage } from 'src/modules/products-screens/products-chat/components/welcome-message';
import { HorizontalCardsGrid } from "src/components/grids/horizontal-cards-grid";
import { ArtworksController } from "src/modules/artworks-screens/components/artworks-controller";

export const ArtworksPage = memo(() => {
  const [selectedArtworkIds, setSelectedArtworkIds] = useState<string[]>([]);
  const [artworksData, setArtworksData] = useState<ArtworkDataType[]>([]);
  const [selectedArtworkData, setSelectedArtworkData] = useState<ArtworkDataType | null>(null);

  const [artworkInfoOpened, { open: openArtworktInfo, close: closeArtworsInfo }] = useDisclosure();
  const [filterOpened, { open: openFilter, close: closeFilter }] = useDisclosure(false);

  const queryClient = useQueryClient();
  const { user, getAccessTokenSilently } = useAuth0();

  const { data: artworksQuery, isSuccess, isLoading } = useQuery({
    queryKey: ["artworks"],
    queryFn: async () => {
      return getArtworks(await getAccessTokenSilently());
    },
  });

  const artworks = useMemo(() => {
    return isSuccess ? artworksQuery?.data : [];
  }, [artworksQuery?.data, isSuccess]);

  useEffect(() => {
    setArtworksData(artworks);
  }, [artworks]);

  const handleArtworkSelect = (artworkId: string) => {
    if (selectedArtworkIds.includes(artworkId)) {
      const filteredArtworks = selectedArtworkIds.filter((id) => id !== artworkId);
      setSelectedArtworkIds(filteredArtworks);
    } else {
      setSelectedArtworkIds([...selectedArtworkIds, artworkId]);
    }
  };

  const handleDeleteSelected = async () => {
    const token = await getAccessTokenSilently();
    await deleteArtwork(token, selectedArtworkIds);
    setSelectedArtworkIds([]);
    await queryClient.invalidateQueries({ queryKey: ["artworks"] });
  };

  const handleSearchIconClick = (artworkData: ArtworkDataType) => {
    openArtworktInfo();
    setSelectedArtworkData(artworkData);
  };

  if (isLoading) {
    return (
      <PageLayout>
        <Flex justify="center" align="center" h="100%">
          <Loader />
        </Flex>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {selectedArtworkData && <ArtworkInfo opened={artworkInfoOpened} artworkData={selectedArtworkData} close={closeArtworsInfo} />}

      <div className="flex flex-col h-full w-full justify-between overflow-hidden p-5 md:px-8">
        <div className="flex flex-row gap-5 justify-between items-center w-full">
          <Filter<ArtworkDataType>
            opened={filterOpened}
            open={openFilter}
            close={closeFilter}
            type="artworks"
            setFilteredData={setArtworksData}
          />
          <div className="flex flex-wrap gap-2">
            <ArtworksFilterPills />
          </div>
        </div>

        <div className={`w-full overflow-hidden h-full ${artworksData?.length === 0 ? 'flex items-center justify-center' : ''}`}>
          {!isLoading && (
            artworksData?.length === 0 ? (
              <div className="h-full flex items-center">
                <WelcomeMessage
                  userName={user?.nickname || 'User'}
                  message="It looks like you haven't created any artworks yet. Start by creating your first artwork to see it displayed here."
                />
              </div>
            ) : (
              <HorizontalCardsGrid
                footer={
                  <ArtworksController
                    isArtworksSelected={selectedArtworkIds.length !== 0}
                    handleDeleteSelected={handleDeleteSelected}
                  />
                }
              >
                {artworksData?.map((artworkData) => (
                  <CheckboxCardElem
                    key={artworkData.artwork_id}
                    id={String(artworkData.artwork_id)}
                    selectedIds={selectedArtworkIds}
                    handleSearchIconClick={() => handleSearchIconClick(artworkData)}
                    handleSelect={handleArtworkSelect}
                  >
                    <ArtworkCard
                      key={artworkData.artwork_id}
                      artworkData={artworkData}
                    />
                  </CheckboxCardElem>
                ))}
              </HorizontalCardsGrid>
            )
          )}
        </div>
      </div>
    </PageLayout>
  );
});
