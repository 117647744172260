// components/AdvancedFiltersSearch.tsx
import React, { useState, useEffect } from 'react';
import { TextInput, Button, Group, Select } from '@mantine/core';
import { ProductDataType } from 'src/models/product';

interface AdvancedFiltersSearchProps {
  products: ProductDataType[];
  category?: string[];
  onFilter: (filteredProducts: ProductDataType[]) => void;
}

export const AdvancedFiltersSearch: React.FC<AdvancedFiltersSearchProps> = ({ products, category, onFilter }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    filterProducts();
  }, [searchQuery, selectedCategory, products]);

  const filterProducts = () => {
    const lowercasedQuery = searchQuery.toLowerCase();
    let filtered = products;

    // Filter based on search query
    if (lowercasedQuery) {
      filtered = filtered.filter(product =>
        String(product.id).toLowerCase().includes(lowercasedQuery)
      );
    }

    // Filter based on selected category
    if (selectedCategory) {
      filtered = filtered.filter(product =>
        product.theme && product.theme.toLowerCase() === selectedCategory.toLowerCase()
      );
    }

    onFilter(filtered); // Apply filters directly here
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.currentTarget.value);
  };

  const handleCategoryChange = (value: string | null) => {
    setSelectedCategory(value ?? "");
  };

  const clearSearch = () => {
    setSearchQuery('');
    setSelectedCategory('');
  };

  const applyFilters = () => {
    onFilter(products);
  };
  // This function will collect all unique categories from the products array
  const getUniqueCategories = (products: ProductDataType[]): string[] => {
    const categories = new Set(products
      .map(product => product.theme)
      .filter((category): category is string => category !== null)
    );
    return Array.from(categories);
  };


const uniqueCategories = getUniqueCategories(products);


  return (
    <Group>
      <TextInput
        placeholder="Search by name or SKU..."
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ flexGrow: 1 }}
      />
      <Select
        data={uniqueCategories}
        value={selectedCategory}
        onChange={handleCategoryChange}
        placeholder="Select a category"
        clearable
      />
      <Button onClick={clearSearch}>Clear</Button>
    </Group>
  );
};

